import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import "./Tutorials.css";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { dbTutorials, firestore } from "../firebase";
import { useUserAuth } from "../context/UserAuthContext";
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactPlayer from 'react-player'
import { Modal } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from "uuid";
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import TutorialsDataService from "../Services/tutorials.services";
import Loading from "./Loading";

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    borderRadius: "10px"
  },
  '& label.Mui-focused': {
    color: '#ffffffcf',
    borderRadius: "10px"

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(56, 161, 105)',
    background: '#1a1a1a',
    borderRadius: "10px"
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff47',
    borderColor: 'rgb(56, 161, 105)',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffffcf',
      background: "#1e1e1e",
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "6px"
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px",
        background: "#1a1a1a"
      },
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white', // Cambia el color del texto del label
    '& .MuiCheckbox-root': {
      color: 'white', // Cambia el color del checkbox
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'white', // Cambia el color del checkbox cuando está seleccionado
    },
  },
}));

const Tutorials = () => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [publico, setPublico] = useState(false);
  const [description, setDescription] = useState("");
  const [tutorials, setTutorials] = useState([{}]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [tutorialId, setTutorialId] = useState("false");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const { loadSpin, loadingComponent, userInfo } = useUserAuth();
  const id = uuidv4();
  const classes = useStyles();

  const getTutorialsData = async () => {
    loadSpin(true);
    try {
      const ref = collection(firestore, "Tutorials");
      const q = query(ref, where("publico", "==", true));

      const items = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });

      setTutorials(items);
    } catch (error) {
      console.error("Error al obtener los tutoriales:", error);
    } finally {
      loadSpin(false);
    }
  };


  const getTutorialsDataById = async (id) => {
    const ref = collection(firestore, "Tutorials");
    const q = query(ref, where("id", "==", id));

    const item = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push({ ...doc.data(), id: doc.id });
    });
    setTitle(item[0].title)
    setDescription(item[0].description)
    setUrl(item[0].url)
    setPublico(item[0].publico)
    setOpenModalEdit(true)
    setTutorialId(item[0].id)
  };

  const handleAddTutorial = async (e) => {
    e.preventDefault();
    setMessage("");
    if (title === "" || description === "" || url === "" || publico === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    const newEvent = {
      title,
      description,
      url,
      publico,
      id: id
    };

    try {
      await setDoc(doc(dbTutorials, "Tutorials", id), newEvent);
      setMessage({ error: false, msg: "¡Nuevo tutorial añadido!" });
      window.location.reload(false);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (title === "" || description === "" || url === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    try {
      const newEvent = {
        title,
        description,
        url,
        publico,
      };
      await TutorialsDataService.updateTutorial(tutorialId, newEvent);
      setMessage({ error: false, msg: "Tutorial actualizado!" });
      window.location.reload(false);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const handleDelete = async (e, id) => {
    setTutorialId(id)
    setOpenModalDelete(true)
  };

  const deleteTutorial = async (e) => {
    e.preventDefault();
    try {
      await TutorialsDataService.deleteTutorial(tutorialId);
      setMessage({ error: false, msg: "¡Evento Eliminado!" });
      window.location.reload(false);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const onCancel = () => {
    setOpenModal(false);
    setOpenModalDelete(false)
    setOpenModalEdit(false)
    setTitle("")
    setDescription("")
    setUrl("")
    setMessage("")
  };

  useEffect(() => {
    getTutorialsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loadingComponent &&
        <Loading />
      }
      {!loadingComponent &&
        <div className='container-calendar' style={{ padding: "20px 0 3em 0" }}>
          <div className="site-calendar-demo-card">
            <div className="container-tutorials">
              {/* crear tutorial */}
              <Modal
                open={openModal}
                onCancel={onCancel}
                footer={null}
                centered
              >
                <Box component="form" noValidate sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: 20 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px", color: "#fff" }} >
                    Crear Tutorial
                  </Typography>
                  {(userInfo.type === "admin" || userInfo.type === "finance") &&
                    <div>
                      <div className="modal-container-tutorials">
                        <Box component="form" noValidate sx={{ mt: 2 }}>
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formTitle"
                            label="Título"
                            name="title"
                            onChange={(e) => setTitle(e.target.value)}
                            inputProps={{
                              style: {
                                height: "25px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formDescription"
                            label="Descripción"
                            name="description"
                            onChange={(e) => setDescription(e.target.value)}
                            inputProps={{
                              style: {
                                height: "25px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formUrl"
                            label="Url del video"
                            name="url"
                            onChange={(e) => setUrl(e.target.value)}
                            inputProps={{
                              style: {
                                height: "25px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                          <FormControlLabel
                            required
                            control={<Checkbox />}
                            label="Público"
                            onChange={(e) => setPublico(e.target.checked)}
                            classes={{
                              root: classes.root,
                            }}
                          />
                          {message?.msg && (
                            <Alert
                              severity={message?.error ? "error" : "success"}
                              dismissible
                              onClose={() => setMessage("")}
                            >
                              {message?.msg}
                            </Alert>
                          )}
                        </Box>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", padding: "2em" }}>
                        <Button
                          fullWidth
                          variant="contained"
                          size="large"
                          sx={{ mt: 5, mb: 4 }}
                          style={{
                            color: "#FFFFFF",
                            borderRadius: 15,
                            marginTop: "1em",
                            backgroundColor: "rgb(120, 17, 19)",
                            padding: "10px 36px",
                            fontSize: "14px"
                          }}
                          onClick={(e) => handleAddTutorial(e)}
                        >
                          Crear
                        </Button>
                      </div>
                    </div>}
                </Box>
              </Modal>
              {/* editar tutorial */}
              <Modal
                open={openModalEdit}
                onCancel={onCancel}
                footer={null}
                centered
              >
                <Box component="form" noValidate sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: 20 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px", color: "#fff" }} >
                    Editar Tutorial
                  </Typography>
                  {(userInfo.type === "admin" || userInfo.type === "finance") &&
                    <div>
                      <div className="modal-container-tutorials">
                        <Box component="form" noValidate sx={{ mt: 2 }}>
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formTitle"
                            label="Título"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            inputProps={{
                              style: {
                                height: "25px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formDescription"
                            label="Descripción"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            inputProps={{
                              style: {
                                height: "25px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formUrl"
                            label="Url del video"
                            name="url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            inputProps={{
                              style: {
                                height: "25px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                          <FormControlLabel
                            required
                            control={<Checkbox />}
                            label="Público"
                            checked={publico}
                            onChange={(e) => setPublico(e.target.checked)}
                            classes={{
                              root: classes.root,
                            }}
                          />
                          {message?.msg && (
                            <Alert
                              severity={message?.error ? "error" : "success"}
                              dismissible
                              onClose={() => setMessage("")}
                            >
                              {message?.msg}
                            </Alert>
                          )}
                        </Box>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", padding: "2em" }}>
                        <Button
                          fullWidth
                          variant="contained"
                          size="large"
                          sx={{ mt: 5, mb: 4 }}
                          style={{
                            color: "#FFFFFF",
                            borderRadius: 15,
                            marginTop: "1em",
                            backgroundColor: "rgb(120, 17, 19)",
                            padding: "10px 36px",
                            fontSize: "14px"
                          }}
                          onClick={(e) => handleEdit(e)}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>}
                </Box>
              </Modal>
              {/* eliminar modal */}
              <Modal
                open={openModalDelete}
                onCancel={onCancel}
                footer={null}
                centered
                title=""
              >
                <Box component="form" noValidate sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: 20 }} align="left" style={{ fontWeight: "bold", paddingTop: "1px", color: "#fff" }} >
                    Éste video se eliminará, ¿Estás seguro?
                  </Typography>
                  {(userInfo.type === "admin" || userInfo.type === "finance") &&
                    <div className="containter-flex"
                      style={{ justifyContent: "right" }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 4 }}
                        style={{
                          color: "#FFFFFF",
                          borderRadius: 15,
                          marginTop: "1em",
                          marginRight: "5px",
                          backgroundColor: "rgba(10, 171, 0, 0.76)",
                          padding: "10px 36px",
                          fontSize: "14px",
                          width: "100px"

                        }}
                        onClick={(e) => deleteTutorial(e)}
                      >
                        <div className="containter-flex">
                          <Typography sx={{ fontSize: 16 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px" }} >
                            Sí
                          </Typography></div>
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 4 }}
                        style={{
                          color: "#FFFFFF",
                          borderRadius: 15,
                          marginTop: "1em",
                          backgroundColor: "rgb(229 0 4 / 86%)",
                          padding: "10px 36px",
                          fontSize: "14px",
                          width: "110px"
                        }}
                        onClick={(e) => setOpenModalDelete(false)}
                      >
                        <div className="containter-flex">
                          <Typography sx={{ fontSize: 16 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px" }} >
                            Cancelar
                          </Typography></div>
                      </Button>
                    </div>}
                  {message?.msg && (
                    <Alert
                      severity={message?.error ? "error" : "success"}
                      dismissible
                      onClose={() => setMessage("")}
                    >
                      {message?.msg}
                    </Alert>
                  )}
                </Box>
              </Modal>
              <div>
                {(userInfo.type === "admin" || userInfo.type === "finance") &&
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="button-add-url">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 4 }}
                        style={{
                          color: "#FFFFFF",
                          borderRadius: 15,
                          marginTop: "1em",
                          backgroundColor: "rgb(120, 17, 19)",
                          padding: "10px 36px",
                          fontSize: "14px",

                        }}
                        onClick={(e) => setOpenModal(true)}
                      >
                        <div className="containter-flex"><AddIcon />
                          <Typography sx={{ fontSize: 16 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px" }} >
                            Agregar Tutorial
                          </Typography></div>
                      </Button>
                    </div>
                  </div>}
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  {(userInfo.type !== "admin" || userInfo.type !== "finance") &&
                    <Typography sx={{ fontWeight: "bold", fontSize: 22 }} color={'#fff'} align="center" style={{ paddingBottom: "5px" }} >
                      Tutoriales
                    </Typography>}
                  {tutorials.map((doc, index) => (
                    <div style={{ marginBottom: "2em" }}>
                      <div className="containter-flex">
                        <div className="container-title-video">
                          <Typography sx={{ fontSize: 22 }} color={'#fff'} align="left" style={{ paddingBottom: "0px" }} >
                            {doc.title}
                          </Typography>
                        </div>
                        {(userInfo.type === "admin" || userInfo.type === "finance") &&
                          <div className="containter-flex">
                            <EditIcon
                              className="buttons-card-video"
                              style={{ backgroundColor: "rgb(10 171 0 / 76%)" }}
                              onClick={(e) => getTutorialsDataById(doc.id)}></EditIcon>
                            <DeleteIcon
                              className="buttons-card-video"
                              onClick={(e) => handleDelete(e, doc.id)}></DeleteIcon>
                          </div>}
                      </div>
                      <Typography sx={{ fontSize: 15 }} color={'#fff'} align="left" style={{ paddingBottom: "5px" }}>
                        {doc.description}
                      </Typography>
                      <ReactPlayer
                        width="100%"
                        height="250px"
                        url={doc.url} />
                    </div>
                  ))}
                </Box>
              </div>
            </div>
          </div>
        </div>}
    </div >
  );
};
export default Tutorials;
