import React, { useEffect, useState, useContext } from "react";
import { SportIdAdmin } from '../../context/fightsContext';
import Box from '@mui/material/Box';
import { useNavigate, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { db } from "../../firebase";
import { collection, query, orderBy, updateDoc, doc, where, limit, startAfter, getDocs, getCountFromServer } from "firebase/firestore";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import { sportDescStatus } from "../../utils/constants";
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import style from "styled-components";
import { useUserAuth } from "../../context/UserAuthContext";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BlockIcon from '@mui/icons-material/Block';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white', // Cambia el color del texto de los elementos de paginación
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)', // Cambia el color de fondo del elemento seleccionado
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)', // Cambia el color de fondo al pasar el mouse sobre un elemento
    },
  },
})

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
    background: "#1A1A1A",
  },
});

const CssTextField = styled(TextField)({
  width: "100%",
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
    background: '#1a1a1a'
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
      background: "#1a1a1a",
      borderColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
        background: "#1a1a1a"
      },
    }
  },
});

const ContainerPeleasText = style.div`
padding: 2em 0em 0em 0em;
`;

const ContainerGeneral = style.div`
    padding: 0em 5em 2em;
    background: rgb(14, 14, 14);

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
}
`;

function SportsList() {
  const navigate = useNavigate();
  const [sports, setSports] = useState([]);

  //===== PAGINATION ========================
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const classes = useStyles();
  const { userInfo } = useUserAuth();
  const { setSportIdAdmin } = useContext(SportIdAdmin);
  const [idEvents, setIdEvents] = useState([]);
  const [privateEvents, setPrivateEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateOptions = {
    month: "long",
    day: "numeric",
    year: 'numeric'
  };

  const handleSportView = (id) => {
    setSportIdAdmin(id);
  };

  const getSportIdHandler = async (id) => {
    navigate("/editar-juego/" + id)
  };

  const privateHandler = async (id, current) => {
    let boolHelpState;
    if (current) {
      boolHelpState = false;
    } else boolHelpState = true;

    const sportDoc = doc(db, "Events", id);
    await updateDoc(sportDoc, {
      privateEvent: boolHelpState
    });
  };

  const setIdEvent = (id, privateEvent) => {
    setIdEvents(id);
    setPrivateEvents(privateEvent)
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [listSize, setListSize] = useState(null);
  
  const getEvents = async (page) => {
    const { pageSize, pageStarts } = pagination;
    const startAfterPage = pageStarts[page - 1];

    let queryPastFights = query(
      collection(db, "Events"),
      orderBy("date", "desc"),
      limit(pageSize)
    );

    if (startAfterPage) {
      queryPastFights = query(queryPastFights, startAfter(startAfterPage));
    }

    if (search.length > 3) {
      queryPastFights = query(
        queryPastFights,
        where("eventName", ">=", search),
        where("eventName", "<=", search + "\uf8ff")
      );
    }

    const snapshot = await getDocs(queryPastFights);
    const docs = snapshot.docs;
    const newItems = docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log(newItems);

    setSports(newItems);

    if (page === pageStarts.length && docs.length === pageSize) {
      pageStarts.push(docs[docs.length - 1]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setPagination((prev) => ({ ...prev, page: newPage }));
    getEvents(newPage);
  };

  const getEventsCount = async () => {
    let queryEvents = query(collection(db, "Events"));

    if (search.length > 3) {
      queryEvents = query(
        queryEvents,
        where("eventName", ">=", search),
        where("eventName", "<=", search + "\uf8ff")
      );
    }

    const snapshotCount = await getCountFromServer(queryEvents);
    setListSize(snapshotCount.data().count);
  };

  useEffect(() => {
    getEvents(1);
    getEventsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <ContainerPeleasText>
          <div>
            <Typography component="h1" variant="h4" align="left" className={classes.typography} style={{ fontSize: "2.125rem" }}>
              Deportes

            </Typography>
          </div>
          <div style={{ display: "flex", margin: "2em 0em 2em 0em" }}>
            <div style={{ width: "60%", marginRight: "1em" }}>
              <CssTextField id="busqueda" variant="outlined" placeholder="Búsqueda"
                sx={{ width: { sm: "50px", md: "300px", lg: "320px" } }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div style={{ width: "40%", display: "flex", justifyContent: "right", alignItems: "center" }}>
              <AddCircleIcon
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={(e) => navigate("/crear-juego")}
                style={{
                  color: "rgb(56, 161, 105)",
                  fontSize: "40px",
                  cursor: "pointer"
                }}>
              </AddCircleIcon>
              {userInfo.type === "admin" &&
                <InsertDriveFileIcon variant="contained" color="primary" size="large"
                  onClick={(e) => navigate("/report-sport")}
                  style={{
                    color: "#EA9F26",
                    fontSize: "40px",
                    marginLeft: "20px",
                    cursor: "pointer"
                  }}>
                  Reporte
                </InsertDriveFileIcon>
              }
            </div>
          </div>
        </ContainerPeleasText>
      </Box>
      <TableContainer component={Paper} style={{ borderRadius: 20, background: "rgb(14, 14, 14)" }}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableBody>
              {sports.map((doc, index) => (
                <TableRowStyled
                  key={doc.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    <Box
                      component="img"
                      alt="Imagen previa"
                      src={doc.previousImage}
                      style={{ width: "70px", height: "70px", marginLeft: "8px", borderRadius: 14, }}
                    />
                  </TableCell>
                  <TableCell align="center" component={Link} to={`/sportview-admin/${doc.id}`} className={classes.typography}>{doc.eventName}</TableCell>
                  <TableCell align="center" component={Link} to={`/sportview-admin/${doc.id}`} className={classes.typography}>{doc.description}</TableCell>
                  <TableCell align="center" component={Link} to={`/sportview-admin/${doc.id}`} className={classes.typography}>
                    {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                  </TableCell>
                  <TableCell align="center" component={Link} to={`/sportview-admin/${doc.id}`} className={classes.typography}>{sportDescStatus[doc.status]}</TableCell>
                  <TableCell align="center" onClick={(e) => setIdEvent(doc.id, doc.privateEvent)} >
                    <div style={{ cursor: "pointer" }}>
                      <MenuIcon
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        style={{ color: "rgb(56, 161, 105)" }}
                        onClick={handleClick}
                      >
                      </MenuIcon>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      > <Link to={`/sportview-admin/${idEvents}`}>
                          <MenuItem onClick={handleClose}>
                            <VisibilityIcon sx={{ fontSize: "20px", color: "#EA9F26", display: "flex", marginRight: "3px" }}
                              style={{ borderRadius: 24, padding: "0px", textDecoration: 'none' }}
                              onClick={(e) => handleSportView(setIdEvents)}>
                            </VisibilityIcon>
                            Ver evento
                          </MenuItem>
                        </Link>
                        <MenuItem onClick={handleClose}>
                          <div onClick={(e) => getSportIdHandler(idEvents)}>
                            <EditIcon sx={{ fontSize: "20px", color: "rgb(56, 161, 105)", marginRight: "3px" }}
                              style={{ borderRadius: 24, padding: "0px", }}>
                            </EditIcon>Editar
                          </div>
                        </MenuItem>
                        <MenuItem onClick={handleClose}> <>
                          {privateEvents &&
                            <div onClick={(e) => privateHandler(idEvents, privateEvents)}>
                              <BlockIcon sx={{ fontSize: "20px", color: "#E81A1A", marginRight: "3px" }}
                                style={{ backgroundColor: "gray", borderRadius: 24, padding: "10px 10px", }}
                                onClick={(e) => privateHandler(doc.id, doc.privateEvent)}>
                              </BlockIcon>
                            </div>
                          }
                          {!privateEvents &&
                            <div onClick={(e) => privateHandler(idEvents, privateEvents)}>
                              <BlockIcon sx={{ fontSize: "20px", color: "#E81A1A", marginRight: "3px" }}
                                style={{ backgroundColor: "#1A1A1A", borderRadius: 24, padding: "0px", }}
                                onClick={(e) => privateHandler(idEvents, privateEvents)}>
                              </BlockIcon>
                            </div>
                          }
                        </>Bloquear
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                </TableRowStyled>
              ))}
            </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
        <Button
          disabled={currentPage === 1}
          style={{ background: 'none', border: 'none' }}
          onClick={() => handlePageChange(currentPage - 1)}>
          <LeftOutlined style={{ color: "white" }} />
        </Button>
        <p style={{color: 'white'}}>{currentPage} de {Math.ceil(listSize / pagination.pageSize)}</p>
        <Button
          disabled={currentPage === Math.ceil(listSize / pagination.pageSize)}
          style={{ background: 'none', border: 'none' }}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <RightOutlined style={{ color: "white" }} />
        </Button>
      </div>
      <Box sx={{ mt: 10, mb: 5 }}>
        <hr></hr>
      </Box>
    </ContainerGeneral>
  );
};

export default SportsList;
