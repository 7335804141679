import React, { useRef } from 'react';
import styled from 'styled-components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Estilos para el contenedor del chat
const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #1C1C1C;
  border-radius: 8px;
  overflow: hidden;
  background-color: #141414;
`;

// Estilos para el área de mensajes
const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
`;

// Estilos para el área de entrada de texto
const InputContainer = styled.div`
  display: flex;
  padding: 8px;
  border-top: 1px solid #1C1C1C;
  background-color: #141414;
`;

// Estilos para el input de texto
const TextInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #1C1C1C;
  background-color: #141414;
  color: #fff;
  border-radius: 4px;
  margin-right: 8px;
`;

// Estilos para el botón de enviar
const SendButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background-color: #38A169;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// Estilos para el contenedor del mensaje
const MessageContainer = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid ${({ isAdmin }) => (isAdmin ? 'red' : '#1C1C1C')};
  background-color: #141414;
  border-radius: 8px;
  max-width: 60%;
  float: left;
  align-self: ${({ isUser }) => (isUser ? 'flex-end' : 'flex-start')};
`;

// Estilos para el mensaje de texto del usuario
const TextMessageUser = styled.div`
  font-size: 13px;
  color: #fff;
  text-align: ${({ isUser }) => (isUser ? 'right' : 'left')};
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Chat = ({ messages, handleSend, active, handleDelete, isAdmin, style }) => {
  const [inputValue, setInputValue] = React.useState('');
  const messagesEndRef = useRef(null);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputValue.trim() !== '') {
      handleSend(inputValue);
      setInputValue('');
    }
  };

  return (
    <ChatContainer style={style}>
      <MessagesContainer>
        {messages.map((message, index) => (
          <MessageContainer isUser={message.isUser} isAdmin={message.isAdmin} key={index}>
            <TextMessageUser isUser={message.isUser}>
              {message.name}:  {message.text}
            </TextMessageUser>
            <TextMessageUser>
              {new Date(message.sendDate.seconds * 1000).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", })}
            </TextMessageUser>
            {isAdmin && (
              <DeleteForeverIcon
                sx={{fontSize: "18px", color: "#742425", pt: 1}}
                style={{backgroundColor: "#fff", borderRadius: 12, padding: "4px 4px",}}
                onClick={(e) => handleDelete(message.id)}
              />
            )}
          </MessageContainer>
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        {active ? (
          <Form onSubmit={handleSendMessage}>
            <TextInput
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Escribe un mensaje..."
            />
            <SendButton type="submit">Enviar</SendButton>
          </Form>
        ) : (
          <span style={{ color: '#fff'}}>Chat deshabilitado</span>
        )}
      </InputContainer>
    </ChatContainer>
  );
};

export default Chat;