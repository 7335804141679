import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { doc, updateDoc, getDoc, collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import style from "styled-components";
import { makeStyles } from '@material-ui/core';
import Loading from "./Loading";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
});

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    borderRadius: "10px"
  },
  '& label.Mui-focused': {
    color: '#ffffffcf',
    borderRadius: "10px"

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(56, 161, 105)',
    background: '#1a1a1a',
    borderRadius: "10px"
  },

  '& .MuiInputLabel-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffffcf',
      background: "#1e1e1e",
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px",
        background: "#1a1a1a"
      },
    }
  },
});

const CssDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-withBorderColor': {
    color: '#ffffffcf',
    borderBottom: "1px solid #101010",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "14.8px",
    textAlign: "center"
  },
  '& .MuiDataGrid-columnHeadersInner': {
    background: "#1a1a1a",
    width: "500px",
    borderRadius: "10px 10px 0px 0px",
    border: "0px solid #fff0"
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: "0px solid #ffffff"
  },
  '& .MuiTablePagination-toolbar': {
    color: "#ffffff"
  }
})

const ContainerPerfil = style.div`
background: rgb(20, 20, 20);
    width: 100%;
    border-radius: 10px;
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 2em;

     @media (max-width: 1000px) {
   padding: 0em;
  }
`;

const ContainerDataGrid = style.div`
height: 400px;
    width: 100%;
    background-color: rgb(20, 20, 20);
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius: 10px;
    padding-bottom: 4px;

     @media (max-width: 1000px) {
   width: 100%;
  }
`;

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "22px",
    fontWeight: "700",
    lineHeight: "16.8px",
    textAlign: "left"
  },
});

const Perfil = () => {
  const { loadSpin, loadingComponent, user } = useUserAuth();
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [nombreUneditable, setNombreUneditable] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(false);

  const [nom, setNom] = useState("");
  const [tel, setTel] = useState("");
  const [ema, setEma] = useState("");
  const [dir, setDir] = useState("");

  const [tabValue, setTabValue] = useState(0);
  //0 = Historial
  //1 = Editar perfil

  const [userBets, setUserBets] = useState([]);
  const [rouletteBets, setRouletteBets] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const blockEdit = false;
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const dateOptions = {
    month: "long",
    day: "numeric",
    year: 'numeric'
  };

  const getData = async () => {
    loadSpin(true);
    try {
      const userDoc = doc(firestore, "Users", user.uid);
      const docSnap = await getDoc(userDoc);
  
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setNombre(userData.userName || "");
        setTelefono(userData.phoneNumber || "");
        setEmail(userData.email || "");
        setDireccion(userData.address || "");
        setNombreUneditable(userData.userName || "");
      } else {
        console.warn("No se encontró el documento del usuario.");
      }
    } catch (error) {
      console.error("Error al obtener los datos del usuario:", error);
    } finally {
      loadSpin(false);
    }
  };
  

  const getBetsRoulleteData = async () => {
    const betsCollectionRef = collection(firestore, "RouletteBets");
    const q = query(betsCollectionRef, where("uidUser", "==", user.uid));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    if (items !== null) {
      setRouletteBets(items);
    }
  };

  const getBetsData = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(betsCollectionRef, where("uidUser", "==", user.uid));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    if (items !== null) {
      setUserBets(items);
    }
  };

  const columnsBetsRoulette = [
    { field: 'amount', headerName: 'Precio', width: 80 },
    { field: 'selectedNumber', headerName: 'Número', width: 70 },
    { field: 'status', headerName: 'Estatus', width: 110 },
    { field: 'date', headerName: 'Fecha', width: 200 },
  ];

  const rowsRouletteBets = rouletteBets.map((row) => ({
    id: row.id,
    amount: row.amount,
    selectedNumber: row.selectedNumber,
    status: row.status,
    date: new Date(row.date.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const columnsBets = [
    { field: 'bet', headerName: 'Apuesta', width: 80 },
    { field: 'color', headerName: 'Color', width: 75 },
    { field: 'status', headerName: 'Estatus', width: 95 },
    { field: 'date', headerName: 'Fecha', width: 210 },
  ];

  const rowsBets = userBets.map((row) => ({
    id: row.id,
    bet: row.bet,
    color: row.color,
    status: row.status,
    date: new Date(row.date.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const getDepositsData = async () => {
    const depositsCollectionRef = collection(firestore, "Deposits");
    const q = query(depositsCollectionRef, where("uid", "==", user.uid), orderBy("depositDate", "desc"));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    if (items !== null) {
      setDeposits(items);
    }
  };

  const columnsDeposits = [
    { field: 'amount', headerName: 'Cantidad', width: 100 },
    { field: 'status', headerName: 'Estatus', width: 110 },
    { field: 'depositDate', headerName: 'Fecha', width: 180 },
  ];

  const rowsDeposits = deposits.map((row) => ({
    id: row.id,
    amount: row.amount,
    status: row.status,
    depositDate: new Date(row.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const getWithdrawalsData = async () => {
    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const q = query(withdrawalsCollectionRef, where("uid", "==", user.uid));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    if (items !== null) {
      setWithdrawals(items);
    }
  };

  const columnsWithdrawals = [
    { field: 'moneyAmount', headerName: 'Cantidad', width: 110 },
    { field: 'status', headerName: 'Estatus', width: 120 },
    { field: 'withdrawalDate', headerName: 'Fecha', width: 180 },
  ];

  const rowsWithdrawals = withdrawals.map((row) => ({
    id: row.id,
    moneyAmount: row.moneyAmount,
    status: row.status,
    withdrawalDate: new Date(row.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const handleEdit = async () => {
    setNom(nombre);
    setTel(telefono);
    setEma(email);
    setDir(direccion);
    setError(false);
    setOpenError(false);
    setSuccess(false);
    setOpen(false);

    setEditMode(true);
  }

  const saveChanges = async () => {
    setError(false);
    setOpenError(false);
    setSuccess(false);
    setOpen(false);

    if (nom === nombre && tel === telefono && ema === email && dir === direccion) {
      setEditMode(false);
      return;
    }

    if (nombre === "") {
      setOpenError(true);
      setError(true);
      return;
    }
    if (!error) {
      const userDoc = doc(firestore, "Users", user.uid);
      try {
        await updateDoc(userDoc, {
          userName: nombre,
          phoneNumber: telefono,
          email: email,
          address: direccion
        });
        setNombreUneditable(nombre);
        setSuccess(true);
        setOpen(true);
        setEditMode(false);
      } catch (err) {
        console.log(err);
        setOpenError(true);
        setError(true);
      }
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    var stringArray = name.split(/(\s+)/);
    if (stringArray.length === 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      };
    }
    if (stringArray.length >= 2) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
  }

  useEffect(() => {
    if (user?.uid) {
      getData();
      getBetsData();
      getBetsRoulleteData();
      getDepositsData();
      getWithdrawalsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {loadingComponent &&
        <Loading />
      }
      {!loadingComponent &&
        <div style={{ backgroundColor: "rgb(14, 14, 14)" }}>
          <Container maxWidth="sm" style={{ backgroundColor: "rgb(14, 14, 14)" }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: "80vh"
              }}
            >
              <ContainerPerfil>
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ width: "90%" }}>
                  <Grid item xs={3} sx={{ mt: 2, mr: 1 }} >
                    <Avatar style={{ width: 54, height: 54, backgroundColor: "#781113" }} {...stringAvatar(nombreUneditable)} />
                  </Grid>
                  <Grid item xs={8} sx={{ mt: 2, }} >
                    <Typography sx={{ mb: 1, }} className={classes.typography} color={'#ffffff'} style={{ fontSize: "17px", fontWeight: "normal" }} >
                      {nombreUneditable.split(" ")[0]}
                    </Typography>
                    <Typography className={classes.typography} color={'#ffffff'} style={{ fontSize: "13px", fontWeight: "normal" }}>
                      Perfil de usuario
                    </Typography>
                  </Grid>
                </Grid>
                <StyledTabs
                  value={tabValue}
                  onChange={handleChange}
                  indicatorColor=""
                  textColor="inherit"
                  sx={{ width: "100%", mt: 2 }}
                >
                  <Tab label="Editar perfil" sx={{ width: "50%" }} style={{ back: "green", color: "#ffffff", fontSize: "15px", fontWeight: "normal" }} className={classes.typography} />
                  <Tab label="Historial" sx={{ width: "50%", color: "#ffffff" }} style={{ fontSize: "15px", fontWeight: "normal" }} className={classes.typography} />
                </StyledTabs>
                {tabValue === 1 &&
                  <Box sx={{
                    width: "100%",
                    justifyContent: 'center',
                    flexDirection: 'column',
                    mt: 4,
                    mb: 2,
                  }}
                  >
                    <div style={{ padding: "1em" }}>
                      <Typography className={classes.typography} color={'#ffffff'} style={{ fontSize: "21px", fontWeight: "normal" }} sx={{ mb: 1 }}>
                        Apuestas
                      </Typography>
                      <ContainerDataGrid>
                        <CssDataGrid style={{ color: "#ffffff", borderColor: "rgb(26 26 26 / 0%)" }}
                          rows={rowsBets}
                          columns={columnsBets}
                          autoPageSize={true}
                        />
                      </ContainerDataGrid>
                      <Typography className={classes.typography} color={'#ffffff'} style={{ fontSize: "21px", fontWeight: "normal" }} sx={{ mt: 5, mb: 1 }}>
                        Ruletas
                      </Typography>
                      <ContainerDataGrid>
                        <CssDataGrid style={{ color: "#ffffff", borderColor: "rgb(26 26 26 / 0%)" }}
                          rows={rowsRouletteBets}
                          columns={columnsBetsRoulette}
                          autoPageSize={true}
                        />
                      </ContainerDataGrid>
                      <Typography className={classes.typography} component="h1" variant="h5" color={'#ffffff'} style={{ fontSize: "21px", fontWeight: "normal" }} sx={{ mt: 5, mb: 1 }} >
                        Depósitos
                      </Typography>
                      <ContainerDataGrid>
                        <CssDataGrid style={{ color: "#ffffff", borderColor: "rgb(26 26 26 / 0%)" }}
                          rows={rowsDeposits}
                          columns={columnsDeposits}
                          autoPageSize={true}
                        />
                      </ContainerDataGrid>
                      <Typography className={classes.typography} component="h1" variant="h5" color={'#ffffff'} style={{ fontSize: "21px", fontWeight: "normal" }} sx={{ mt: 5, mb: 1 }}>
                        Retiros
                      </Typography>
                      <ContainerDataGrid>
                        <CssDataGrid style={{ color: "#ffffff", borderColor: "rgb(26 26 26 / 0%)" }}
                          rows={rowsWithdrawals}
                          columns={columnsWithdrawals}
                          autoPageSize={true}
                        />
                      </ContainerDataGrid>
                    </div>
                  </Box>}
                {tabValue === 0 &&
                  <Box sx={{
                    width: "90%",
                    justifyContent: 'center',
                    flexDirection: 'column',
                    p: 3,
                    m: 2,
                    borderRadius: 2,
                  }}>
                    <Divider sx={{ mb: 1 }} />
                    <CssTextField
                      margin="normal"
                      required
                      id="Nombre"
                      label="Nombre"
                      fullWidth
                      value={nombre}
                      InputProps={{
                        readOnly: !editMode,
                      }}
                      onChange={(e) => setNombre(e.target.value)}

                    />
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <CssTextField
                      id="Telefono"
                      label="Teléfono:"
                      fullWidth
                      value={telefono}
                      InputProps={{
                        readOnly: !editMode,
                      }}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <CssTextField
                      id="Email"
                      label="Email:"
                      fullWidth
                      value={email}
                      InputProps={{
                        readOnly: true,
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <CssTextField
                      id="Direccion"
                      label="Dirección:"
                      fullWidth
                      value={direccion}
                      InputProps={{
                        readOnly: !editMode,
                      }}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                    {success ?
                      <Collapse in={open}>
                        <Alert
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mt: 3 }}
                        >
                          Tus cambios se han guardado!
                        </Alert>
                      </Collapse> : <></>}
                    {error ?
                      <Collapse in={openError}>
                        <Alert severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenError(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mt: 3 }}
                        >
                          Comprueba que tus datos esten completos!
                        </Alert>
                      </Collapse> : <></>}
                    {blockEdit !== false &&
                      <div>
                        {!editMode ?
                          <Button
                            style={{
                              borderRadius: 35,
                              backgroundColor: "#781113",
                              padding: "6px 30px",
                              fontSize: "14px"
                            }}
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 4, mb: 2 }}
                            onClick={(e) => handleEdit()}
                          >
                            Editar
                          </Button> :
                          <Button
                            style={{
                              borderRadius: 35,
                              backgroundColor: "#603B9A",
                              padding: "10px 105px",
                              fontSize: "14px"
                            }}
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 5, mb: 2 }}
                            onClick={(e) => saveChanges()}
                          >
                            Guardar
                          </Button>
                        }
                      </div>}
                  </Box>}
              </ContainerPerfil>
            </Box>
          </Container>
        </div>
      }
    </>
  );
};

export default Perfil;
