import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import NotificationsForm from "../components/NotificationsForm";
import NotificationsList from "../components/NotificationsList";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useUserAuth } from "../context/UserAuthContext";
import Loading from "../components/Loading";

function Notifications() {
    const { setOpenModal, setStateModal, notifications } = useUserAuth();
    const [loading, setLoading] = useState(true);

    const handleCreate = () => {
        setOpenModal(true)
        setStateModal("creating")
    };

    useEffect(() => {
        if (notifications !== null) {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Container component="main" maxWidth="lg" style={{ minHeight: "600px" }}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}>
                    <div className="button-add-url">
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 5, mb: 4 }}
                            style={{
                                color: "#FFFFFF",
                                borderRadius: 15,
                                marginTop: "1em",
                                backgroundColor: "rgb(120, 17, 19)",
                                padding: "10px 36px",
                                fontSize: "14px",
                            }}
                            onClick={handleCreate}
                        >
                            <div style={{ display: "flex" }} className="container-flex">
                                <AddIcon />
                                <Typography
                                    sx={{ fontSize: 16 }}
                                    align="center"
                                    style={{ fontWeight: "bold", paddingTop: "1px" }}
                                >
                                    Nueva notificación
                                </Typography>
                            </div>
                        </Button>
                    </div>
                </div>
                <NotificationsForm dataNotification={null} />
                {loading ? (
                    <Typography sx={{ mt: 5 }} align="center" style={{ color: "#fff" }}>
                       <Loading/>
                    </Typography>
                ) : (
                    <NotificationsList value={notifications} />
                )}
            </Container>
        </>
    );
}

export default Notifications;
