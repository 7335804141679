import React, { useState, useEffect, useRef } from "react";
import { firestore } from "../../firebase";
import { collection, query, Timestamp, getDocs, where } from "firebase/firestore";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { format, addDays } from 'date-fns';
import Loading from "../../components/Loading";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as rdrLocales from 'react-date-range/dist/locale';
import style from "styled-components";
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  }
})

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
    background: "#1A1A1A",
  },
});

const ContainerGeneral = style.div`
    padding: 2em 5em 2em;
    background: rgb(14, 14, 14);
    min-height:800px;

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
         min-height:500px;
}
`;

const Retentions = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [retentions, setRetentions] = useState(0);
  const refOne = useRef(null)
  
  const classes = useStyles();

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])

  const onChange = (values) => {
    if (values) {
      const startDate = Timestamp.fromDate(values[0].startDate).toDate()
      const endDate = Timestamp.fromDate(values[0].endDate).toDate()
      getBetsEarningsByDay(startDate, endDate)
      setRange(values)
    }
  }

  const openCalendar = () => {
    if (openDateRange === false) {
      setOpenDateRange(true)
    }
  }

  const startAt = (date) => {
    const parsed = format(date, 'yyyy-MM-dd 00:00:00.000');
    return new Date(parsed);
  };

  const endAt = (date) => {
    const parsed = format(date, 'yyyy-MM-dd 23:59:00.000');
    return new Date(parsed);
  };

  const getBetsEarningsByDay = async (startDateRange, endDateRange) => {
    console.log(startDateRange, endDateRange)
    setLoading(true);
    setOpenDateRange(false)
    const startDate = startAt(startDateRange);
    const endDate = endAt(endDateRange);
    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const withdrawalDate = query(withdrawalsCollectionRef, where('withdrawalDate', '>=', startDate), where('withdrawalDate', '<=', endDate));
    let dayWithdrawals = [];
    const querySnapshot = await getDocs(withdrawalDate);
    let totalRetentions = 0;
    querySnapshot.forEach(async (doc) => {
      const item = { ...doc.data(), id: doc.id };
      const date = item.withdrawalDate.toDate().toLocaleDateString("es-MX");
      const ret = item?.retention || 0;
      console.log(ret);
      totalRetentions += ret;
      dayWithdrawals.push({ ...item, fecha: date });
    });

    console.log(dayWithdrawals);
    setRetentions(totalRetentions);
    setWithdrawals(dayWithdrawals);
    setLoading(false);
    setOpenDateRange(false)
  }

  useEffect(() => {
    const currentDate = new Date();
    getBetsEarningsByDay(currentDate, currentDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <Typography variant="body1" color="white"
              style={{
                borderRadius: 8,
                backgroundColor: "#781113",
                padding: "10px 18px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
              onClick={(e) => openCalendar()}>
              <CalendarMonthIcon /><p style={{ marginBottom: 0 }}>Seleccionar Fecha</p>
              <div className="calendarWrap">
                <div ref={refOne}>
                  {openDateRange &&
                    <DateRange
                      locale={rdrLocales.es}
                      onChange={item => onChange([item.selection])}
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                      ranges={range}
                      months={1}
                      direction="horizontal"
                      className="calendarElement"
                    />
                  }
                </div>
              </div>
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="white" >Total de retenciones: $ {retentions}</Typography>
          </div>
        </div>
        {loading ? <Loading /> :
          <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)", marginTop:"2em" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableRowStyled>
                <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Usuario</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Nombre</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Email</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Fecha</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Banco</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Retiro</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Retención</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>Total pagado</TableCell>
                  <TableCell align="center" className={classes.typography} style={{fontSize:"15px", fontWeight:"bold"}}>No. Tarjeta</TableCell>
                </TableRowStyled>
              <TableBody>
                {withdrawals.map((doc, index) => (
                  <TableRowStyled
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" className={classes.typography}>{doc.userName}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.name}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.email}</TableCell>
                    <TableCell align="center" className={classes.typography}>{doc.fecha}</TableCell>
                    <TableCell align="left"  className={classes.typography}>{doc.bank}</TableCell>
                    <TableCell align="center"  className={classes.typography}>$ {doc.moneyAmount}</TableCell>
                    <TableCell align="center" className={classes.typography}>$ {doc?.retention || 0}</TableCell>
                    <TableCell align="center"  className={classes.typography}>$ {doc.moneyAmount - (doc?.retention || 0)} </TableCell>
                    <TableCell align="center"  className={classes.typography}>{doc.cardNumber}</TableCell>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </ContainerGeneral  >
    </>
  )
}

export default Retentions;

