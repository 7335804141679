import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { IconButton, Snackbar } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CancelIcon from '@mui/icons-material/Cancel';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore, uploadFile } from "../firebase";
import { collection, getDocs, query, where, updateDoc, doc, orderBy } from "firebase/firestore";
import { Modal, Spin } from 'antd';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Link } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { Button as BtnComp } from "../components/ui"
import { styled } from '@mui/material/styles';
import style from "styled-components";

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
    background: "#1A1A1A",
  },
});

const ContainerCard = style.div`
    width: 60%;

@media screen and (min-width: 0px) and (max-width: 1000px) {
        width: 100%;
}
`;

const Deposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [idDeposit, setIdDeposit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);

  const { user, userInfo } = useUserAuth();

  const getDeposits = async () => {
    setLoading(true);
    const depositsCollectionRef = collection(firestore, "Deposits");
    const q = query(depositsCollectionRef, where("uid", "==", user.uid), orderBy("depositDate", "desc"));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    setDeposits(items);
    setLoading(false);
  };

  useEffect(() => {
    getDeposits();
    // eslint-disable-next-line
  }, []);

  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  const confirm = async () => {
    setLoadingCancel(true);
    setButtonPressed(true)
    try {
      const depositDoc = doc(firestore, "Deposits", idDeposit);
      await updateDoc(depositDoc, {
        status: "cancelado",
      });
      setLoadingCancel(false);
      window.location.reload(true);
    } catch (error) {
      console.error("Error cancel: ", error);
      setLoadingCancel(false);
    }
  };

  const handleCancel = (id) => {
    setOpenModalCancel(true)
    setIdDeposit(id)
  }

  const handleClickCopySPEI = (value) => {
    setOpenCopy(true);
    navigator.clipboard.writeText(value);
  };

  const handleUploadedFile = async (e, uid) => {
    e.preventDefault();
    setLoading(true);
    try {
      const depositDoc = doc(firestore, "Deposits", uid);
      const urlFile = await uploadFile(e.target.files[0]);
      await updateDoc(depositDoc, {
        voucher: urlFile,
      });
      const newDeposits = deposits.map((deposit) => {
        if (deposit.id === uid) {
          return {
            ...deposit,
            voucher: urlFile,
          };
        }
        return deposit;
      });
      setDeposits(newDeposits);
      setLoading(false);
      setOpenModal(true);
    } catch (error) {
      console.error("Error adding document: ", error);
      setLoading(false);
    }
  }

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
  });

  const DocuPDF = ({ amount }) => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "38px" }}>Ficha digital</Text>
          </View>
          <View style={{ padding: 30, backgroundColor: "#E3E3E3", marginTop: "20px" }}>
            <Text style={{ fontSize: "18px" }}>
              Instrucciones
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "10px" }}>
              1. Realiza tu pago con cualquiera de los establecimientos permitidos.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              2. Toma una foto de tu comprobante de pago.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              3. Ingresa a tu cuenta y dirigete a "Agregar saldo".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              4. Navega en la parte inferior, verás el estado de tu saldo a añadir. Toca o da click en "Subir comprobante".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              5. En los proximos minutos un miembro de nuestro equipo revisará tu comprobante y se añadirá el saldo a tu cuenta.
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            <Text style={{ color: "gray", fontWeight: "extrabold", fontSize: "20px" }}>MONTO A PAGAR</Text>
            <Text style={{ marginTop: "20px", fontWeight: "bold", fontSize: "32px" }}>
              $ {amount} MXN
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Transferencia electrónica {"(SPEI)"}:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              CLABE: 058320000000893020
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Banco: Banregio
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Deposito en efectivo:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              Número de tarjeta: 4741 7406 0220 7885
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Beneficiario: GalloWin
            </Text>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "20px" }}>
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Oxxo_Logo.svg/2560px-Oxxo_Logo.svg.png"
                alt="OXXO"
                style={{ maxWidth: "80px", maxHeight: "40px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/7-eleven_logo.svg/2110px-7-eleven_logo.svg.png"
                alt="7Eleven"
                style={{ maxWidth: "40px", maxHeight: "40px", marginLeft: "30px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/en/b/be/Tiendas_Extra_logo.png"
                alt="Extra"
                style={{ maxWidth: "100px", maxHeight: "100px", paddingBottom: "50px", marginLeft: "25px" }}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <Spin
      size="large"
      spinning={loading}
    >
      <div style={{ backgroundColor: "#0E0E0E" }}>
        <Container component="main" maxWidth="md" style={{ backgroundColor: "#0E0E0E" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: "15px"
            }}
          >
            <Container component="main" maxWidth="md" style={{ backgroundColor: "#0E0E0E" }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ContainerCard>
                  <Box sx={{ mt: 2 }}>
                    <Card sx={{ width: 'auto' }} style={{ backgroundColor: '#141414' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                          Deposito en efectivo:
                        </Typography>
                        <Grid container >
                          <Grid item xs={10}>
                            <Typography variant="body2" color="#ffffff">
                              Número de tarjeta: 4741 7406 0220 7885
                              <br />
                              Beneficiario: GalloWin
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("4741 7406 0220 7885")}>
                              <ContentCopyIcon />
                            </IconButton>
                            <Snackbar
                              message="SPEI copiado"
                              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                              autoHideDuration={1500}
                              onClose={() => setOpenCopy(false)}
                              open={openCopy}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </ContainerCard>
              </Box>
            </Container>
            <Container component="main" maxWidth="md" style={{ backgroundColor: "#0E0E0E" }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ContainerCard>
                  <Box sx={{ mt: 2 }}>
                    <Card sx={{ width: 'auto' }} style={{ backgroundColor: '#141414' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                          Transferencia electrónica {"(SPEI)"}:
                        </Typography>
                        <Grid container >
                          <Grid item xs={10}>
                            <Typography variant="body2" color="#ffffff">
                              CLABE: 058320000000893020
                              <br />
                              Banco: Banregio
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("058320000000893020")}>
                              <ContentCopyIcon />
                            </IconButton>
                            <Snackbar
                              message="SPEI copiado"
                              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                              autoHideDuration={1500}
                              onClose={() => setOpenCopy(false)}
                              open={openCopy}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </ContainerCard>
              </Box>
            </Container>
            <Container component="main" maxWidth="md" style={{ backgroundColor: "#0E0E0E" }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ContainerCard>
                  <Box sx={{ mt: 2 }}>
                    <Card sx={{ width: 'auto' }} style={{ backgroundColor: '#141414' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                          SOLO PARA PAGO EXPRESS {"(SPEI)"}:
                        </Typography>
                        <Grid container >
                          <Grid item xs={10}>
                            <Typography variant="body2" color="#ffffff">
                              CLABE: {userInfo?.clabeClubPago}
                              <br />
                              Referencia: {userInfo?.referenceClubPago}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("646180227772531359")}>
                              <ContentCopyIcon />
                            </IconButton>
                            <Snackbar
                              message="SPEI copiado"
                              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                              autoHideDuration={1500}
                              onClose={() => setOpenCopy(false)}
                              open={openCopy}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </ContainerCard>
              </Box>
            </Container>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", paddingTop: "20px" }}>
              <h2 style={{ color: "#fff" }}>Depositos</h2>
              <Link to={"/add-money"}>
                <Typography variant="body1" color="white"
                  style={{
                    minHeight: "36px",
                    borderRadius: 8,
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "rgb(56, 161, 105)",
                    padding: "4px 16px",
                    fontSize: "15px"
                  }}>
                  Depositar
                </Typography>
              </Link>
            </div>
            <TableContainer style={{ borderRadius: 20, background: "#141414" }}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                  <TableRowStyled>
                    <TableCell style={{ color: "#ffffff" }}>Fecha</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Estatus</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Cantidad</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Metodo</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Formato de pago</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Subir comprobante</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Cancelar</TableCell>
                  </TableRowStyled>
                </TableHead>
                <TableBody>
                  {deposits.map((doc, index) => (
                    <TableRowStyled
                      key={doc.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" style={{ fontSize: "14px", color: '#fff' }}>
                        {new Date(doc.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                      </TableCell>
                      {doc.status === "waiting" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#b35426' }}>En espera</TableCell>
                      }
                      {doc.status === "approved" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>Aprobado</TableCell>
                      }
                      {doc.status === "denied" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#ad1510' }}>Declinado</TableCell>
                      }
                      {doc.status === "cancelado" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#ad1510' }}>Cancelado</TableCell>
                      }
                      <TableCell align="right" style={{ fontSize: "14px", color: '#fff' }}>+ ${doc.amount}</TableCell>
                      <TableCell align="right" style={{ fontSize: "14px", color: '#fff' }}>{doc.payMethod}</TableCell>
                      <TableCell align="center">
                        {(doc.payMethod === "tienda" || doc.payMethod === "spei") &&
                          <a href={doc?.payFormat} target="_blank" rel="noreferrer">
                            <SimCardDownloadIcon style={{ fontSize: "24px", color: '#50AF48' }} />
                          </a>
                        }
                        {(doc.payMethod === "default" && doc.status === "waiting") &&
                          <PDFDownloadLink document={<DocuPDF amount={doc.amount} />} fileName="ficha.pdf">
                            <IconButton
                              color="primary"
                              aria-label="descargar ficha"
                              component="label"
                              size="large"
                            >
                              <SimCardDownloadIcon style={{ fontSize: "24px", color: '#50AF48' }} />
                            </IconButton>
                          </PDFDownloadLink>
                        }
                      </TableCell>
                      {(doc.voucher === "" && doc.payMethod === "default" && doc.status === "waiting") &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>
                          <IconButton color="primary" aria-label="Subir comprobante" component="label" size="large">
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              name=""
                              id=""
                              onChange={e => handleUploadedFile(e, doc.id)}
                            />
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                              <Grid item xs={12}>
                                <AttachFileIcon />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography component="h1" variant="h5" color="primary" align="center" style={{ fontSize: "10px" }}>
                                  Subir archivo
                                </Typography>
                              </Grid>
                            </Grid>
                          </IconButton>
                        </TableCell>
                      }
                      {(doc.voucher !== "" && doc.payMethod === "default" && doc.status !== "denied") &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>
                          {(doc.aui === "" ?
                            <IconButton color="primary" aria-label="Subir comprobante" component="label" size="large">
                              <input
                                hidden
                                accept="image/*"
                                type="file"
                                name=""
                                id=""
                                onChange={e => handleUploadedFile(e, doc.id)}
                              />
                              <CheckCircleOutlineOutlinedIcon style={{ fontSize: "24px", color: 'green' }} />
                            </IconButton>
                            :
                            <CheckCircleOutlineOutlinedIcon style={{ fontSize: "24px", color: 'green' }} />
                          )}
                        </TableCell>
                      }
                      {(doc.voucher === "" && doc.payMethod === "default" && doc.status === "approved") && (
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>
                          <CheckCircleOutlineOutlinedIcon style={{ fontSize: "24px", color: 'green' }} />
                        </TableCell>
                      )}
                      {(doc.payMethod === "default" && doc.status === "denied") && (
                        <TableCell align="center" style={{ fontSize: "14px", color: '#ad1510' }}>
                          Declinado
                        </TableCell>
                      )}
                      {(doc.payMethod !== "default" || doc.status === "cancelado") &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>
                          <p style={{ color: "#fff" }}>No aplica</p>
                        </TableCell>
                      }
                      {doc.status === "waiting" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#ad1510' }}>
                          <IconButton
                            onClick={e => handleCancel(doc.id)}>
                            <CancelIcon
                              style={{ fontSize: "24px", color: 'red' }}
                            />
                          </IconButton>
                        </TableCell>
                      }
                      {doc.status !== "waiting" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#ad1510' }}>
                          <IconButton
                            disabled={true}
                            onClick={e => handleCancel(doc.id)}>
                            <CancelIcon
                              style={{ fontSize: "24px", color: '#00000000' }}
                            />
                          </IconButton>
                        </TableCell>
                      }
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>

        {/* Modal Success Upload */}
        <Modal
          open={openModal}
          closable={true}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          footer={null}
          centered
        >
          <div>
            <Typography component="h1" variant="h5" color={'#50AF48'} align="center" >
              Tu comprobante fue enviado con éxito!
            </Typography>
            <br></br>
            <Typography variant="body2" align="center" color={'#fff'}>
              Revisaremos tu comprobante! Tu nuevo saldo se verá reflejado en tu cuenta pronto.
            </Typography>

            <BtnComp
              style={{
                marginTop: "1em",
              }}
              onClick={() => setOpenModal(false)}
            >
              Aceptar
            </BtnComp>
          </div>
        </Modal>
        {/* modal confirm  */}
        <Modal
          open={openModalCancel}
          closable={true}
          onOk={() => setOpenModalCancel(false)}
          onCancel={() => setOpenModalCancel(false)}
          footer={null}
          centered
        >
          <div>
            <Typography component="h1" variant="h5" color={'#fff'} align="center" >
              ¿Cancelar depósito?
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}>
              <Spin
                size="large"
                spinning={loadingCancel}>
              </Spin>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {!buttonPressed &&
                <Button
                  style={{
                    borderRadius: 35,
                    backgroundColor: "rgb(60 167 73)",
                    padding: "10px 10px",
                    fontSize: "14px",
                    marginRight: "1em"
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 4, mb: 4 }}
                  onClick={confirm}
                >
                  Sí
                </Button>
              }
              {buttonPressed &&
                <Button
                  style={{
                    borderRadius: 35,
                    backgroundColor: "rgb(60 167 73)",
                    padding: "10px 10px",
                    fontSize: "14px",
                    marginRight: "1em"
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 4, mb: 4 }}
                  onClick={confirm}
                  disabled
                >
                  Sí
                </Button>
              }
              {!buttonPressed &&
                <Button
                  style={{
                    borderRadius: 35,
                    backgroundColor: "rgb(191 20 20)",
                    padding: "10px 10px ",
                    fontSize: "14px"
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 4, mb: 4 }}
                  onClick={() => setOpenModalCancel(false)}
                >
                  No
                </Button>
              }
              {buttonPressed &&
                <Button
                  style={{
                    borderRadius: 35,
                    backgroundColor: "rgb(191 20 20)",
                    padding: "10px 10px ",
                    fontSize: "14px"
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 4, mb: 4 }}
                  onClick={() => setOpenModalCancel(false)}
                  disabled
                >
                  No
                </Button>
              }
            </div>
          </div>
        </Modal>
      </div>
    </Spin>

  );
};

export default Deposits;
