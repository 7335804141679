import React, { useState, useEffect } from "react";
import { updateDoc, setDoc, doc } from "firebase/firestore";
import { db, firestore } from "../firebase";
import "./Tutorials.css";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import { useUserAuth } from "../context/UserAuthContext";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from "antd";

const CssTextField = styled(TextField)({
    '& MuiFormHelperText-root': {
        color: '#ffffffcf',
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px"
    },
    '& label.Mui-focused': {
        color: '#ffffffcf',
        borderRadius: "10px"

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(56, 161, 105)',
        background: '#1a1a1a',
        borderRadius: "10px"
    },

    '& .MuiInputLabel-root': {
        color: '#ffffff47',
        borderColor: 'rgb(56, 161, 105)',
        fontFamily: "Outfit",
        fontSize: "18px",
        lineHeight: "16.8px",
        textAlign: "center",
        borderRadius: "10px"
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(56, 161, 105)',
            borderRadius: "10px"
        },
    },
    '& .MuiInputBase-input': {
        '&.MuiOutlinedInput-input': {
            color: '#ffffffcf',
            background: "#1e1e1e",
            borderColor: 'rgb(56, 161, 105)',
            borderRadius: "6px"
        },
        '& .MuiOutlinedInput-root:hover': {
            '&.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(56, 161, 105)',
                borderRadius: "10px",
                background: "#1a1a1a"
            },
        }
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        '& .MuiCheckbox-root': {
            color: 'white',
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'white',
        },
    },
}));

const NotificationsForm = ({ dataNotification }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [saveNotification, setSaveNotification] = useState(false);
    const [message, setMessage] = useState({ error: false, msg: "" });
    const { setInfoMessage, setOpenModal, openModal, stateModal, alert } = useUserAuth();
    const id = uuidv4();
    const classes = useStyles();

    const handleAddTutorial = async (e) => {
        e.preventDefault();
        setMessage("");
        if (title === "" || description === "") {
            setMessage({ error: true, msg: "Campos vacios!!" });
            return;
        }
        if (alert) {
            setMessage({ error: true, msg: "Acabas de enviar una notificación, espera unos segundos!" });
            setOpenModal(false)
            return;
        }
        const newEvent = {
            title,
            description,
            id: id,
            status: saveNotification,
            public: true
        };

        try {
            await setDoc(doc(db, "Notifications", id), newEvent);
            setMessage({ error: false, msg: "¡Nuevo tutorial añadido!" });
            const data = {
                "title": title,
                "description": description
            }
            setOpenModal(false)
            setInfoMessage(data)
            setMessage({ error: false, msg: "¡Notificación enviada!" });
            setTitle("")
            setDescription("")
            setSaveNotification(false)
            setTimeout(async () => {
                const notificationsDoc = doc(firestore, "Notifications", id);
                await updateDoc(notificationsDoc, {
                    public: false,
                });
            }, 8000);

            return;
        } catch (err) {
            setMessage({ error: true, msg: err.message });
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        setMessage("");
        if (title === "" || description === "") {
            setMessage({ error: true, msg: "Campos vacios!!" });
            return;
        }
        try {
            const notificationsDoc = doc(firestore, "Notifications", dataNotification?.id);
            await updateDoc(notificationsDoc, {
                title,
                description
            });
            setMessage({ error: false, msg: "Se guardó correctamente" });
            setOpenModal(false)

        } catch (err) {
            setMessage({ error: true, msg: err.message });
        }
    };

    const handleClose = () => {
        setOpenModal(false)
        setTitle("")
        setDescription("")
    }

    const sendNotification = async () => {
        if (alert) {
            setMessage({ error: true, msg: "Acabas de enviar una notificación, espera unos segundos!" });
            setOpenModal(false)
            return;
        }
        const notificationsDoc = doc(firestore, "Notifications", dataNotification.id);
        setOpenModal(false)
        await updateDoc(notificationsDoc, {
            public: true,
        });
        setInfoMessage(dataNotification)
        setMessage({ error: false, msg: "¡Notificación enviada!" });
        setTimeout(async () => {
            await updateDoc(notificationsDoc, {
                public: false,
            });
        }, 8000);
    }

    useEffect(() => {
        setTimeout(() => {
            setMessage("")
        }, 4000);

        // eslint-disable-next-line
    }, [message]);


    useEffect(() => {

        if (dataNotification?.title) {
            setTitle(dataNotification?.title)
        }
        if (dataNotification?.description) {
            setDescription(dataNotification?.description)
        }
        // eslint-disable-next-line
    }, [dataNotification]);

    return (
        <div >
            {message?.msg && (
                <Alert
                    severity={message?.error ? "error" : "success"}
                    dismissible
                    onClose={() => setMessage("")}
                >
                    {message?.msg}
                </Alert>
            )}
            {stateModal === "creating" &&
                <Modal
                    open={openModal}
                    onCancel={handleClose}
                    footer={null}
                    centered
                >
                    <Box component="form" noValidate sx={{ mt: 2 }}>
                        <div>
                            <Typography sx={{ fontSize: 20 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px", color: "#fff" }} >
                                Crear notificación
                            </Typography>
                            <div className="modal-container-tutorials" style={{ padding: "0" }}>
                                <Box component="form" noValidate sx={{ mt: 2 }}>
                                    <CssTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="formTitle"
                                        label="Título"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        inputProps={{
                                            style: {
                                                height: "25px",
                                                paddingLeft: "15px"
                                            },
                                        }}
                                    />
                                    <CssTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="formDescription"
                                        label="Descripción"
                                        name="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        inputProps={{
                                            style: {
                                                height: "25px",
                                                paddingLeft: "15px"
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        required
                                        control={<Checkbox />}
                                        label="¿Guardar alerta?"
                                        checked={saveNotification}
                                        onChange={(e) => setSaveNotification(e.target.checked)}
                                        classes={{
                                            root: classes.root,
                                        }}
                                    />
                                    {message?.msg && (
                                        <Alert
                                            severity={message?.error ? "error" : "success"}
                                            dismissible
                                            onClose={() => setMessage("")}
                                        >
                                            {message?.msg}
                                        </Alert>
                                    )}
                                </Box>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 5, mb: 4 }}
                                    style={{
                                        color: "#FFFFFF",
                                        borderRadius: 15,
                                        marginTop: "1em",
                                        backgroundColor: "rgb(120, 17, 19)",
                                        padding: "10px 36px",
                                        fontSize: "14px"
                                    }}
                                    onClick={(e) => handleAddTutorial(e)}
                                >
                                    Enviar
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }
            {stateModal === "editing" &&
                <Modal
                    open={openModal}
                    onCancel={handleClose}
                    footer={null}
                    centered
                >
                    <Box component="form" noValidate sx={{ mt: 2 }}>
                        <div>
                            <Typography sx={{ fontSize: 20 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px", color: "#fff" }} >
                                Editar notificación
                            </Typography>
                            <div className="modal-container-tutorials" style={{ padding: "0" }}>
                                <Box component="form" noValidate sx={{ mt: 2 }}>
                                    <CssTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="formTitle"
                                        label="Título"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        inputProps={{
                                            style: {
                                                height: "25px",
                                                paddingLeft: "15px"
                                            },
                                        }}
                                    />
                                    <CssTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="formDescription"
                                        label="Descripción"
                                        name="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        inputProps={{
                                            style: {
                                                height: "25px",
                                                paddingLeft: "15px"
                                            },
                                        }}
                                    />
                                    {message?.msg && (
                                        <Alert
                                            severity={message?.error ? "error" : "success"}
                                            dismissible
                                            onClose={() => setMessage("")}
                                        >
                                            {message?.msg}
                                        </Alert>
                                    )}
                                </Box>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 5, mb: 4 }}
                                    style={{
                                        color: "#FFFFFF",
                                        borderRadius: 15,
                                        marginTop: "1em",
                                        backgroundColor: "rgb(120, 17, 19)",
                                        padding: "10px 36px",
                                        fontSize: "14px"
                                    }}
                                    onClick={(e) => handleEdit(e)}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal>}
            {stateModal === "confirmation" &&
                <Modal
                    open={openModal}
                    onCancel={handleClose}
                    footer={null}
                    centered
                >
                    <Box component="form" noValidate sx={{ mt: 2 }}>
                        <div>
                            <Typography sx={{ fontSize: 20 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px", color: "#fff" }} >
                                ¿Enviar notificación a usuarios?
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 5, mb: 4 }}
                                    style={{
                                        color: "#FFFFFF",
                                        borderRadius: 15,
                                        marginTop: "1em",
                                        backgroundColor: "rgb(120, 17, 19)",
                                        padding: "10px 36px",
                                        fontSize: "14px"
                                    }}
                                    onClick={(e) => sendNotification(e)}
                                >
                                    Enviar
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal>}
        </div>
    );
};

export default NotificationsForm;
