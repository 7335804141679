import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { useLocalStorage } from "../useLocalStorage";
import { firestore } from "../firebase";
import { getDoc, doc, addDoc, query, where, updateDoc, collection, getDocs, orderBy, increment, serverTimestamp, startAfter, limit, getCountFromServer } from "firebase/firestore";
import { getAuth, getIdToken } from "firebase/auth";
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DepositsDataService from "../Services/deposits.services"
import { IconButton } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { uploadFile } from "../firebase";
import HistoryDataService from "../Services/history.services";
import { Button as ButtonC } from "./ui";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button as ButtonAnt } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CssTextField = styled(TextField)({
    '& MuiFormHelperText-root': {
        color: '#ffffffcf',
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px"
    },
    '& label.Mui-focused': {
        color: '#ffffffcf',
        borderRadius: "10px"

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(56, 161, 105)',
        background: '#1a1a1a',
        borderRadius: "10px"
    },

    '& .MuiInputLabel-root': {
        color: '#ffffff47',
        borderColor: 'rgb(56, 161, 105)',
        fontFamily: "Outfit",
        fontSize: "18px",
        lineHeight: "16.8px",
        textAlign: "center",
        borderRadius: "10px"
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(56, 161, 105)',
            borderRadius: "10px"
        },
    },
    '& .MuiInputBase-input': {
        '&.MuiOutlinedInput-input': {
            color: '#ffffffcf',
            background: "#1e1e1e",
            borderColor: 'rgb(56, 161, 105)',
            borderRadius: "10px"
        },
        '& .MuiOutlinedInput-root:hover': {
            '&.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(56, 161, 105)',
                borderRadius: "10px",
                background: "#1a1a1a"
            },
        }
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#141414',
    border: '2px solid #1C1C1C',
    borderRadius: 6,
    p: 4,
};

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        color: '#fff',
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#EA9F26',
    },
    '& .MuiTabs-scroller': {
        color: "#fff",
        overflow: 'scroll',

    },
});

const UserDetails = () => {
    const { userInfo } = useUserAuth();
    const [uidUserDetail] = useLocalStorage("uidUserDetail", "");
    const [tabUserDetails, setTabUserDetails] = useLocalStorage("tabUserDetails", 0);
    const [nombre, setNombre] = useState("");
    const [newName, setNewName] = useState("");
    const [telefono, setTelefono] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [email, setEmail] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [direccion, setDireccion] = useState("");
    const [active, setActive] = useState(false);
    const [openModalMoney, setOpenModalMoney] = useState("");
    const [openModalRetiros, setOpenModalRetiros] = useState("");
    const [nombreRetiro, setNombreRetiro] = useState("");
    const [emailRetiro, setEmailRetiro] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [bank, setBank] = useState("");
    const [moneyAmount, setMoneyAmount] = useState();
    const [history, setHistory] = useState([])
    const logsCollectionRef = collection(firestore, "History");
    const [listSize, setListSize] = useState(null);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 5,
        pageStarts: [null],
    });
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setPagination(prev => ({ ...prev, page: newPage }));
        getHistory(newPage);
    };

    const getLogssCount = async () => {
        let q = query(logsCollectionRef,
            where("uidUser", "==", uidUserDetail))
        const snapshotCount = await getCountFromServer(q);
        setListSize(snapshotCount.data().count);
    };

    const getHistory = async (page) => {
        const { pageSize, pageStarts } = pagination;
        const startAfterPage = pageStarts[page - 1];
        let q = query(logsCollectionRef,
            where("uidUser", "==", uidUserDetail), orderBy("date", "desc"), limit(pageSize))

        if (startAfterPage) {
            q = query(q, startAfter(startAfterPage));
        }

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setHistory(items);

        if (page === pageStarts.length && docs.length === pageSize) {
            pageStarts.push(docs[docs.length - 1]);
        }
    };

    const handleChangeTab = (event, newValue) => {
        setTabUserDetails(newValue);
    };

    const handleChangeDep = (event) => {
        if (event.target.value.match(/[^0-9]/)) {
            event.preventDefault();
        }
        setDeposito(parseInt(event.target.value));
    };

    const handleChangeRetiro = (event) => {
        if (event.target.value.match(/[^0-9]/)) {
            event.preventDefault();
        }
        setMoneyAmount(parseFloat(event.target.value))
    };

    const [bets, setBets] = useState([]);
    const [deposits, setDeposits] = useState([]);
    const [withdrawals, setWithdrawals] = useState([]);

    const [withdrawalsApproved, setWithdrawalsApproved] = useState(0);
    const [withdrawalsWaiting, setWithdrawalsWaiting] = useState(0);

    const [openModalAlert, setOpenModalAlert] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");

    const [saldo, setSaldo] = useState(0);
    const [amount, setAmount] = useState(null);

    const [apuestasGanadas, setApuestasGanadas] = useState(0);
    const [gananciaReal, setGananciaReal] = useState(0);
    const [apuestasPerdidas, setApuestasPerdidas] = useState(0);
    const [depositosAprov, setDepositosAprov] = useState(0);
    const [saldoEsperado, setSaldoEsperado] = useState(0);
    const [totalCurrentBet, setTotalCurrentBet] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [buttonPressed, setButtonPressed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDep, setLoadingDep] = useState(false);
    const [deposito, setDeposito] = useState(0);
    const { user, getUserData } = useUserAuth();
    const [handleErrorType, setHandleErrorType] = useState(false);
    const [userName, setUserName] = useState("");
    const [voucher, setVoucher] = useState(""); //URL del voucher
    const [uploadedFile, setUploadFile] = useState(false);
    const [adminUser, setAdminUser] = useState("");

    const validatePaste = (event) => {
        event.preventDefault();
    }

    const handleUploadedFile = async (e, uid, ee) => {
        setLoading(true)
        e.preventDefault();
        const fileInput = document.getElementById('file-input');
        const previewImage = document.getElementById('file-preview');
        try {
            const result = await uploadFile(e.target.files[0]);
            setVoucher(result);
            setUploadFile(true);
        } catch (error) {
        }

        if (e.target.files.length > 0) {
            previewImage.src = URL.createObjectURL(
                e.target.files[0],
            );

            previewImage.style.display = 'block';
        }

        // 👇️ reset file input once you're done
        fileInput.value = null;
        setLoading(false)

    }


    const getBets = async () => {
        // Get Bets
        const betsCollectionRef = collection(firestore, "Bets");
        const q = query(betsCollectionRef, where("uidUser", "==", uidUserDetail));

        // Get Event Bets
        const eventBetsCollectionRef = collection(firestore, "EventsBets");
        const qEvents = query(eventBetsCollectionRef, where("uidUser", "==", uidUserDetail));

        const querySnapshot = await getDocs(q);
        const querySnapshotEvents = await getDocs(qEvents);
        let betsQ = [];
        let betsW = [];
        let betsL = [];
        let betsE = [];
        let betsR = [];

        let betsC = [];
        querySnapshot.forEach((doc) => {
            betsQ.push({ ...doc.data(), id: doc.id, name: doc.data().fightName ? doc.data().fightName : doc.id });

            if (doc.data().status === "apuesta ganadora") {
                betsW.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "apuesta perdedora") {
                betsL.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "empate") {
                betsE.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "rechazada") {
                betsR.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "por aprobar" || doc.data().status === "aceptada") {
                betsC.push({ ...doc.data(), id: doc.id });
            }
        });

        querySnapshotEvents.forEach((doc) => {
            betsQ.push({ ...doc.data(), id: doc.id, name: doc.data().eventName ? doc.data().eventName : doc.id });

            if (doc.data().status === "apuesta ganadora") {
                betsW.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "apuesta perdedora") {
                betsL.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "empate") {
                betsE.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "rechazada") {
                betsR.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "por aprobar" || doc.data().status === "aceptada") {
                betsC.push({ ...doc.data(), id: doc.id });
            }
        });

        let currentBet = (betsC.reduce((a, v) => a = a + v.bet, 0));
        setTotalCurrentBet(currentBet);
        betsQ.sort(function (a, b) {
            const dateA = a.date;
            const dateB = b.date;
            if (dateB < dateA) {
                return -1;
            }
            if (dateB > dateA) {
                return 1;
            }
            return 0;
        });
        setBets(betsQ);

        let ganado = (betsW.reduce((a, v) => a = a + v.bet, 0));
        setApuestasGanadas(ganado);

        let percent = ganado * 0.10;

        let ganancia = ganado - percent;
        setGananciaReal(ganancia);

        let perdido = (betsL.reduce((a, v) => a = a + v.bet, 0));
        setApuestasPerdidas(perdido);

        //Deposits Get
        const depositsCollectionRef = collection(firestore, "Deposits");
        const qD = query(depositsCollectionRef, where("uid", "==", uidUserDetail), orderBy("depositDate", "desc"),);

        const querySnapshotD = await getDocs(qD);
        const itemsFixed = [];
        querySnapshotD.forEach((doc) => {
            if (doc.data().status === "approved") {
                itemsFixed.push({ ...doc.data(), id: doc.id });
            }
        });

        //WITHDRAWALS
        const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
        const qW = query(withdrawalsCollectionRef, where("uid", "==", uidUserDetail), orderBy("withdrawalDate", "desc"),);

        const querySnapshotW = await getDocs(qW);
        const itemsWaiting = [];
        const itemsApproved = [];
        querySnapshotW.forEach((doc) => {
            if (doc.data().status === "waiting") {
                itemsWaiting.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "approved") {
                itemsApproved.push({ ...doc.data(), id: doc.id });
            }
        });
        let withdrawalsApproved = (itemsApproved.reduce((a, v) => a = a + v.moneyAmount, 0));
        let withdrawalsWaiting = (itemsWaiting.reduce((a, v) => a = a + v.moneyAmount, 0));

        const deposits = (itemsFixed.reduce((a, v) => a = a + v.amount, 0));

        const expectedBalance = ((deposits + ganancia) - (perdido + withdrawalsWaiting + withdrawalsApproved + currentBet));

        setSaldoEsperado(expectedBalance);

        setWithdrawalsApproved(withdrawalsApproved);
        setWithdrawalsWaiting(withdrawalsWaiting);
    };

    const getDeposits = async () => {
        const depositsCollectionRef = collection(firestore, "Deposits");
        const q = query(depositsCollectionRef, where("uid", "==", uidUserDetail), orderBy("depositDate", "desc"),);

        const querySnapshot = await getDocs(q);
        const items = [];
        const itemsFixed = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
            if (doc.data().status === "approved") {
                itemsFixed.push({ ...doc.data(), id: doc.id });
            }
        });
        setDeposits(items);

        let depositsFixed = (itemsFixed.reduce((a, v) => a = a + v.amount, 0));
        setDepositosAprov(depositsFixed);
    };

    const getWithdrawals = async () => {
        const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
        const q = query(withdrawalsCollectionRef, where("uid", "==", uidUserDetail), orderBy("withdrawalDate", "desc"),);

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setWithdrawals(items);
    };

    const getData = async () => {
        const userDoc = doc(firestore, "Users", uidUserDetail);
        const docSnap = await getDoc(userDoc);
        setNombre(docSnap.data().userName);
        setNewName(docSnap.data().userName);
        setTelefono(docSnap.data().phoneNumber);
        setNewPhone(docSnap.data().phoneNumber);
        setEmail(docSnap.data().email);
        setNewEmail(docSnap.data().email);
        setDireccion(docSnap.data().address);
        setSaldo(docSnap.data().balance)
        if ((docSnap.data().type !== "banned")) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }

    const handleNewStatus = async (status) => {
        if (status) {
            //Actualizar estatus a banned
            setActive(false);
            updateStatus();
        }
        else {
            //Actualizar estatus a active or " "
            setActive(true);
            updateStatus();
        }
    }

    const updateStatus = async () => {
        let newStatus = "";
        if (active) {
            newStatus = "banned";
        }
        else {
            newStatus = "";
        }
        const userDoc = doc(firestore, "Users", uidUserDetail);
        try {
            await updateDoc(userDoc, {
                type: newStatus
            });
        } catch (err) {
            console.log(err);
        }
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        var stringArray = name.split(/(\s+)/);
        if (stringArray.length === 1) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}`,
            };
        }
        if (stringArray.length >= 2) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        }
    }

    const dateOptions = {
        month: "long",
        day: "numeric",
        year: 'numeric'
    };

    const HandleVoucher = async (voucher) => {
        window.open(voucher, '_blank', 'noreferrer');
    };

    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubtractMoney = () => {
        let approved = true;
        setSuccessMessage("");

        if (!amount) {
            approved = false;
            setAlertMessage("Ingresa una cantidad");
        }

        if (amount <= 0) {
            approved = false;
            setAlertMessage("Ingresa una cantidad mayor a 0");
        }

        if (approved) {
            updateBalanceSubstract();
        }
    }

    const handleAddMoney = () => {
        let approved = true;
        setSuccessMessage("");

        if (!amount) {
            approved = false;
            setAlertMessage("Ingresa una cantidad");
        }

        if (amount <= 0) {
            approved = false;
            setAlertMessage("Ingresa una cantidad mayor a 0");
        }

        if (approved) {
            updateBalanceAdd();
        }
    }

    const updateBalanceAdd = async () => {
        const userDoc = doc(firestore, "Users", uidUserDetail);
        await updateDoc(userDoc, {
            balance: increment(amount)
        });
        setAlertMessage("");
        setAmount(0);
        getData();
        setSuccessMessage("Se actualizo el saldo");
        let description = ("Deposito agregado: $" + amount);
        updateHistory("Deposito agregado", description, uidUserDetail, amount);
    }

    const updateBalanceSubstract = async () => {
        const userDoc = doc(firestore, "Users", uidUserDetail);
        await updateDoc(userDoc, {
            balance: increment(-amount)
        });
        setAlertMessage("");
        setAmount(0);
        getData();
        setSuccessMessage("Se actualizo el saldo");
        let description = ("Deposito restado: $" + amount);
        updateHistory("Deposito restado", description, uidUserDetail, amount);
    }

    const handleSubmitRetiros = async (e) => {
        e.preventDefault();
        const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
        const userRef = doc(firestore, "Users", uidUserDetail);

        if (moneyAmount <= 0) {
            setAlertMessage("Cantidad invalida");
            setSuccessMessage("");
            setSuccess(false);
            return;
        }

        if (nombre === "" || cardNumber === "" || email === "" || bank === "") {
            setAlertMessage("Campos vacios!");
            setSuccessMessage("");
            setSuccess(false);
            return;
        }

        if (saldo < moneyAmount) {
            setAlertMessage("No dispones de esa cantidad");
            setSuccessMessage("");
            setSuccess(false);
            return;
        }
        try {
            const newWithdrawal = {
                nombre: nombreRetiro,
                cardNumber,
                bank,
                email: emailRetiro,
                moneyAmount,
                approvedDate: new Date(),
                aui: adminUser.id,
                authorizedUserName: userInfo.userName,
                status: "approved",
                uid: uidUserDetail,
                voucher: "",
                withdrawalDate: new Date(),
                userName: nombre
            }
            await addDoc(withdrawalsCollectionRef, newWithdrawal)
            await updateDoc(userRef, {
                balance: saldo - moneyAmount
            });
            setSuccessMessage("Tu retiro se ha generado correctamente!");
            window.location.reload();
        } catch (error) {
            console.error(error);
            setAlertMessage("Error inesperado");
            setSuccess(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingDep(true);
        let depositoParse = parseFloat(deposito);
        setDeposito(depositoParse);
        if (!uidUserDetail) {
            setError(true);
            setLoading(false);
            setLoadingDep(false);
            return;
        }
        if (handleErrorType) {
            setError(true);
            setFileError(false);
            setSuccess(false);
            setHandleErrorType(false);
            setLoading(false);
            setLoadingDep(false);
            return;
        }
        if (!deposito) {
            setFileError(false);
            setSuccess(false);
            setError(true);
            setLoading(false);
            setLoadingDep(false);
            return;
        }
        if (!uploadedFile) {
            setFileError(true);
            setSuccess(false);
            setError(false);
            setLoading(false);
            setLoadingDep(false);
            return;
        }
        if (deposito <= 0) {
            setFileError(false);
            setSuccess(false);
            setError(true);
            setLoading(false);
            setLoadingDep(false);
            return;
        }
        try {
            getUserData(user).then((snapshot) => {
                setUserName(snapshot.data().userName);
            });
            setSuccess(true);
            setButtonPressed(true);
            setError(false);
            setFileError(false);
            CreateData()
        } catch (error) {
            console.error(error);
            setError(true);
            setSuccess(false);
            setFileError(false);
            setLoading(false);
        }
    };

    async function CreateData() {
        //Create db structure
        getUserData(user).then((snapshot) => {
            setUserName(snapshot.data().userName);
        });
        const newDeposit = {
            amount: deposito,
            approvedDate: serverTimestamp(),
            aui: adminUser.id,
            authorizedUserName: adminUser.userName,
            depositDate: new Date(),
            payMethod: "default",
            status: "approved",
            uid: uidUserDetail,
            userName,
            voucher: voucher,
        }
        await DepositsDataService.addDeposit(newDeposit);

        const userRef = doc(firestore, "Users", uidUserDetail);
        await updateDoc(userRef, {
            balance: increment(deposito),
            deposits: increment(1),
            depositsTotal: increment(deposito)
        });
        let description = ("Deposito aprobado: $" + deposito);
        createHistory("Deposito aprobado", description, uidUserDetail, deposito);
        window.location.reload();
    }

    const createHistory = async (movementType, description, uidUser, deposito) => {
        let initialValueH = 0;
        let movementTypeH = movementType;
        let descriptionH = description;
        let newBalanceH = 0;
        let uidFightH = "";
        let uidUserH = uidUser;
        const newDate = new Date();

        const docRef = doc(firestore, "Users", uidUser);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            initialValueH = docSnap.data().balance;
            newBalanceH = initialValueH + deposito;
        } else {
            return console.log("No such document!");
        }

        const docData = {
            initialValue: initialValueH,
            newBalance: newBalanceH,
            movementType: movementTypeH,
            description: descriptionH,
            uidUser: uidUserH,
            uidFight: uidFightH,
            date: newDate
        };
        await HistoryDataService.addHistory(docData);
    }

    const updateHistory = async (movementType, description, uidUser, deposito) => {
        let initialValueH = 0;
        let movementTypeH = movementType;
        let descriptionH = description;
        let newBalanceH = 0;
        let uidFightH = "";
        let uidUserH = uidUser;
        const newDate = new Date();

        const docRef = doc(firestore, "Users", uidUser);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            initialValueH = docSnap.data().balance - deposito;
            newBalanceH = initialValueH + deposito;
        } else {
            return console.log("No such document!");
        }

        const docData = {
            initialValue: initialValueH,
            newBalance: newBalanceH,
            movement: deposito,
            movementType: movementTypeH,
            description: descriptionH,
            uidUser: uidUserH,
            uidFight: uidFightH,
            date: newDate,
            authorizedUserName: userInfo.userName,
        };
        await HistoryDataService.addHistory(docData);
        window.location.reload()
    }

    const getDataAdmin = async () => {
        await getUserData(user).then((snapshot) => {
            setAdminUser({ id: snapshot.id, ...snapshot.data() });
        });
    };

    const updateUser = async () => {
        setAlertError(false);
        const auth = getAuth();
        const { currentUser } = auth;
        const token = await getIdToken(currentUser);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
                method: "PATCH",
                body: JSON.stringify({ id: uidUserDetail, userName: newName, phoneNumber: newPhone, email: newEmail }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setOpenModalAlert(true);
            if (response.status !== 200) {
                setAlertError(true);
                setMessageAlert(data?.message || "Error al actualizar usuario");
                return;
            }
            setMessageAlert("Usuario actualizado correctamente");
            getData();
        } catch (error) {
            console.error("Error: ", error)
            setMessageAlert(error?.message);
            setAlertError(true);
            setOpenModalAlert(true);
        }
    };

    useEffect(() => {
        getData();
        getBets();
        getDeposits();
        getWithdrawals();
        getDataAdmin()
        getHistory(pagination.page);
        getLogssCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#141414',
        }}>
            <Container component="main" maxWidth="md">
                {successMessage !== "" &&
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Alert severity="success">{successMessage}</Alert>
                    </Grid>}
                {/* MODAL RETIROS */}
                <Modal
                    onClose={() => {
                        setOpenModalRetiros(false);
                    }}
                    open={openModalRetiros}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid item xs sx={{ mt: 2, }} >
                            <Typography component="h1" variant="h5" color={'#fff'} fontSize="17px" align="center" >
                                Retirar fondos
                            </Typography>
                            <br></br>
                        </Grid>
                        <Typography component="h1" color={'#fff'} variant="body1" fontSize="15px" align="justify" sx={{ width: "90%" }}>
                            Ingresa los datos de tu cuenta bancaria para realizar la solicitud de retiro.
                        </Typography>

                        <Box
                            sx={{
                                width: "90%",
                                p: 3,
                                m: 2,
                            }}>
                            <CssTextField
                                margin="normal"
                                required
                                fullWidth
                                id="formName"
                                label="Nombre completo"
                                name="name"
                                type="text"
                                onChange={(e) => setNombreRetiro(e.target.value)}
                            />
                            <CssTextField
                                margin="normal"
                                required
                                fullWidth
                                id="formCardNumber"
                                label="No. de tarjeta o CLABE"
                                name="cardNumber"
                                onChange={(e) => setCardNumber(e.target.value)}
                            />
                            <CssTextField
                                margin="normal"
                                required
                                fullWidth
                                id="formBank"
                                label="Nombre del banco"
                                name="bank"
                                type="text"
                                onChange={(e) => setBank(e.target.value)}
                            />
                            <CssTextField
                                margin="normal"
                                required
                                fullWidth
                                id="formEmail"
                                label="Ingresa tu email"
                                name="email"
                                autoComplete="email"
                                type="email"
                                onChange={(e) => setEmailRetiro(e.target.value)}
                            />
                            <CssTextField
                                margin="normal"
                                required
                                fullWidth
                                id="formMoneyAmount"
                                label="Cantidad a retirar"
                                name="moneyAmount"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                value={moneyAmount}
                                onChange={handleChangeRetiro}
                                onPaste={validatePaste}
                            />
                        </Box>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Box component="form" onSubmit={handleSubmitRetiros} noValidate sx={{ mt: 2 }}>
                                {alertMessage !== "" &&
                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                        <Alert severity="error">{alertMessage}</Alert>
                                    </Grid>}
                                {successMessage !== "" &&
                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                        <Alert severity="success">{successMessage}</Alert>
                                    </Grid>}

                                <ButtonC
                                    style={{
                                        width: "250px",
                                    }}
                                    type="submit"
                                >
                                    Terminar retiro
                                </ButtonC>
                            </Box>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    onClose={() => {
                        setOpenModalMoney(false);
                    }}
                    open={openModalMoney}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container sx={{ display: 'flex', mb: 3 }} direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <CssTextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="formDeposito"
                                            label="Cantidad a depositar"
                                            type="number"
                                            name="deposito"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            value={deposito}
                                            onChange={handleChangeDep}
                                            onPaste={validatePaste}
                                        />
                                        <IconButton color="primary" aria-label="Subir comprobante" component="label" size="large">
                                            <input
                                                hidden
                                                accept="image/*"
                                                type="file"
                                                name=""
                                                id="file-input"
                                                onChange={e => handleUploadedFile(e, doc.id, e.target.files)}
                                            />
                                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                                <Grid item xs={12}>
                                                    <AttachFileIcon />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h5" color="primary" align="center" style={{ fontSize: "10px" }}>
                                                        Subir archivo
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </IconButton>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "left" }}>
                                        <img style={{ width: "50px", height: "50px", marginRight: "5em" }} src="#"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg";
                                            }}
                                            alt="Preview Uploaded" id="file-preview" />
                                        {loading &&

                                            <CircularProgress color="inherit" style={{ color: "#6750A4" }} />

                                        }
                                    </div>
                                    {success &&
                                        <Alert sx={{ mt: 1, mb: 1 }} severity="success">
                                            Tu petición ha sido enviada con exito!
                                        </Alert>}
                                    {error &&
                                        <Alert sx={{ mt: 1, mb: 1 }} severity="warning">
                                            Ingresa una cantidad valida!
                                        </Alert>}
                                    {fileError &&
                                        <Alert sx={{ mt: 1, mb: 1 }} severity="error">
                                            Debes agregar el compobante!
                                        </Alert>}
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {!buttonPressed &&
                                            <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                                                <ButtonC
                                                    style={{
                                                        width: "250px",
                                                    }}
                                                    type="submit"
                                                >
                                                    Depositar
                                                </ButtonC>
                                            </div>}
                                        {buttonPressed &&
                                            <Button
                                                style={{
                                                    borderRadius: 35,
                                                    backgroundColor: "#E81A1A",
                                                    fontSize: "14px"
                                                }}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                size="large"
                                                disabled
                                                sx={{ mt: 2, mb: 4 }}
                                            >
                                                Depositado
                                            </Button>}
                                    </div>
                                    {loadingDep &&
                                        <Grid container sx={{ display: 'flex', mb: 3 }} direction="column" alignItems="center" justifyContent="center">
                                            <Grid item xs={12}>
                                                <CircularProgress color="inherit" style={{ color: "#6750A4" }} />
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal Alert */}
                <Modal
                    open={openModalAlert}
                    onClose={() => setOpenModalAlert(false)}
                    aria-labelledby="modal-modaFl-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid direction="column" container alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
                                <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center", color: '#fff' }} >
                                    {messageAlert}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                {alertError
                                    ? <ErrorIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "red" }} />
                                    : <CheckCircleOutlineIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "green" }} />
                                }
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
                                <ButtonC
                                    onClick={() => { setOpenModalAlert(false) }}
                                >
                                    Aceptar
                                </ButtonC>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container direction="row" alignItems="flex-start" justifyContent="left" sx={{ width: "90%" }}>
                        <Grid item xs={3} md={2} sx={{ mt: 2, }} >
                            <Avatar style={{ width: 64, height: 64 }} {...stringAvatar(nombre)} />
                        </Grid>
                        <Grid item xs={8} md={10} sx={{ mt: 2, }} >
                            <Typography component="h1" variant="h5" color={'#fff'} style={{ fontSize: "28px", fontWeight: "bold" }} >
                                {nombre.split(" ")[0]}
                            </Typography>
                            <Typography component="h1" variant="body1" color={'#fff'} >
                                Consultando usuario
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{
                        width: "90%",
                        justifyContent: 'center',
                        flexDirection: 'column',
                        p: 3,
                        m: 2,
                        borderRadius: 2,
                    }}>
                        <Divider sx={{ mb: 1 }} />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="Nombre"
                            label="Nombre"
                            name="Nombre"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                        <Divider sx={{ mt: 2, mb: 1 }} />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="Telefono"
                            label="Telefono"
                            name="Telefono"
                            value={newPhone}
                            onChange={(e) => setNewPhone(e.target.value)}
                        />
                        <Divider sx={{ mt: 2, mb: 1 }} />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="Email"
                            label="Email"
                            name="Email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                        <Divider sx={{ mt: 2, mb: 1 }} />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="Dirección"
                            label="Dirección"
                            name="Dirección"
                            value={direccion}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Divider sx={{ mt: 2, mb: 1 }} />
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="Estatus"
                            label="Estatus"
                            name="Estatus"
                            value={active ? "Cuenta activa" : "Cuenta desactivada"}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                    {((newName !== nombre) || (newPhone !== telefono) || (newEmail !== email)) && (
                        <Grid style={{ display: "flex", justifyContent: "center" }} item xs={12} sx={{ mt: 3, ml: 3 }}>
                            <Button variant="outlined" color="error" size="small"
                                onClick={updateUser}
                                style={{
                                    borderRadius: 6,
                                    padding: "12px 50px",
                                    fontSize: "12px"
                                }}>
                                Editar usuario
                            </Button>
                        </Grid>
                    )}
                    <Grid style={{ display: "flex", justifyContent: "center" }} item xs={12} sx={{ mt: 3, ml: 3 }}>
                        <Button variant="outlined" color="error" size="small"
                            onClick={(e) => setOpenModalMoney(true)}
                            style={{
                                borderRadius: 6,
                                padding: "12px 50px",
                                fontSize: "12px"
                            }}>
                            Agregar saldo
                        </Button>
                        <Button variant="outlined" color="error" size="small"
                            onClick={(e) => setOpenModalRetiros(true)}
                            style={{
                                borderRadius: 6,
                                padding: "12px 50px",
                                fontSize: "12px",
                                marginLeft: "1em"
                            }}>
                            Retirar saldo
                        </Button>
                    </Grid>
                    {active &&
                        <Grid container spacing={2} direction="row" alignItems="inherit">
                            <Grid style={{ display: "flex", justifyContent: "center" }} item xs={12} sx={{ mt: 3, ml: 3 }}>
                                <Button variant="outlined" color="error" size="small"
                                    onClick={(e) => handleNewStatus(active)}
                                    style={{
                                        borderRadius: 6,
                                        padding: "12px 50px",
                                        fontSize: "12px"
                                    }}>
                                    Desactivar usuario
                                </Button>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1, mb: 2, ml: 3 }}>
                                <Typography component="h1" variant="h4" color={'#EA9F26'} align="left"
                                    style={{ fontSize: 12, display: "flex", justifyContent: "center" }} >
                                    ¡Desactivar al usuario provocara que no pueda tener acceso a las funciones de GalloWin!
                                </Typography>
                            </Grid>
                        </Grid>}
                    {!active &&
                        <Grid container spacing={2} direction="row" alignItems="inherit">
                            <Grid style={{ display: "flex", justifyContent: "center" }} item xs={12} sx={{ mt: 3, ml: 3 }}>
                                <Button variant="outlined" color="success" size="small"
                                    onClick={(e) => handleNewStatus(active)}
                                    style={{
                                        borderRadius: 6,
                                        padding: "12px 50px",
                                        fontSize: "12px"
                                    }}>
                                    Activar usuario
                                </Button>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1, mb: 2, ml: 3 }}>
                                <Typography component="h1" variant="h4" color={'#6750A4'} align="left"
                                    style={{ fontSize: 12, display: "flex", justifyContent: "center" }} >
                                    ¡Reactivar al usuario provocara que vuelva a tener acceso a las funciones de GalloWin!
                                </Typography>
                            </Grid>
                        </Grid>}
                </Box>

                {/*User details*/}
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className="div-scroll">
                        <Grid container direction="row" alignItems="center" justifyContent="center">
                            <Grid className="container-tabs">
                                <StyledTabs
                                    value={tabUserDetails}
                                    onChange={handleChangeTab}
                                    indicatorColor=""
                                    textColor="inherit"
                                >
                                    <Tab label="Apuestas" style={{ back: "green" }} />
                                    <Tab label="Depositos" />
                                    <Tab label="Retiros" />
                                    <Tab label="Detalles" />
                                    {userInfo?.type === "admin" && (
                                        <Tab label="Editar saldo" sx={{ width: "20%" }} />
                                    )}
                                    {userInfo?.type === "admin" && (
                                        <Tab label="logs" />
                                    )}
                                </StyledTabs>
                            </Grid>
                        </Grid>
                    </div>
                    {tabUserDetails === 0 &&
                        <TableContainer sx={{ mt: 5 }}>
                            <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ color: "white" }}>Pelea/Evento</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Color/Equipo</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Apuesta</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bets.map((doc, index) => (
                                        <TableRow
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" sx={{ color: '#fff' }}>{doc.name}</TableCell>
                                            {doc.color === "Rojo" &&
                                                <TableCell align="center" component="th" scope="row" style={{ fontSize: "14px", color: '#D32F2F' }}>
                                                    {doc.color}
                                                </TableCell>
                                            }
                                            {doc.color === "Verde" &&
                                                <TableCell align="center" component="th" scope="row" style={{ fontSize: "14px", color: '#2E7D32' }}>
                                                    {doc.color}
                                                </TableCell>
                                            }
                                            {doc.teamName &&
                                                <TableCell align="center" component="th" scope="row" style={{ fontSize: "14px", color: '#fff' }}>
                                                    {doc.teamName}
                                                </TableCell>
                                            }
                                            <TableCell align="center" sx={{ color: '#fff' }}>{doc.bet}</TableCell>
                                            <TableCell align="center" sx={{ color: '#fff' }}>{doc.status}</TableCell>
                                            <TableCell align="center" sx={{ color: '#fff' }}>
                                                {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {tabUserDetails === 1 &&
                        <TableContainer sx={{ mt: 5 }}>
                            <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Aprobado por</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Cantidad</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Voucher</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deposits.map((doc, index) => (
                                        <TableRow
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" sx={{ color: "white" }}>
                                                {new Date(doc.approvedDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                            </TableCell>
                                            {doc.status === "denied" &&
                                                <TableCell align="center" style={{ fontSize: "14px", color: '#D32F2F' }}>
                                                    {"Rechazado"}
                                                </TableCell>}
                                            {doc.status === "approved" &&
                                                <TableCell align="center" style={{ fontSize: "14px", color: '#2E7D32' }}>
                                                    {"Aprobado"}
                                                </TableCell>}
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.authorizedUserName}</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.amount}</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>
                                                <InsertDriveFileOutlinedIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#EA9F26', marginTop: "4px" }}
                                                    onClick={(e) => HandleVoucher(doc.voucher)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {tabUserDetails === 2 &&
                        <TableContainer sx={{ mt: 5 }}>
                            <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ color: "white" }}>Nombre</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Email</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Banco</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Retiro</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>Aprobado por</TableCell>
                                        <TableCell align="center" sx={{ color: "white" }}>No. Tarjeta</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {withdrawals.map((doc, index) => (
                                        <TableRow
                                            key={doc.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.name}</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.email}</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>
                                                {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.bank}</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.moneyAmount}</TableCell>
                                            {doc.status === "declined" &&
                                                <TableCell align="center" style={{ fontSize: "14px", color: '#D32F2F' }}>
                                                    {"Declinado"}
                                                </TableCell>}
                                            {doc.status === "approved" &&
                                                <TableCell align="center" style={{ fontSize: "14px", color: '#2E7D32' }}>
                                                    {"Aprobado"}
                                                </TableCell>}
                                            {doc.status === "waiting" &&
                                                <TableCell align="center" style={{ fontSize: "14px", color: "#EA9F26" }}>
                                                    {"En espera"}
                                                </TableCell>}
                                            {doc.authorizedUserName ?
                                                <TableCell align="center" sx={{ color: "white" }}>{doc.authorizedUserName}</TableCell> :
                                                <TableCell align="center">{""}</TableCell>
                                            }
                                            <TableCell align="center" sx={{ color: "white" }}>{doc.cardNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {tabUserDetails === 3 &&
                        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: "90%", mt: 3 }}>
                            <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                                <Paper sx={{ maxHeight: 700, minHeight: 280, minWidth: 320, maxWidth: 800 }} style={{ borderRadius: 20, backgroundColor: "#141414", boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                    <Grid direction="column" container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} md={12} sx={{ mt: 1 }} >
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 2 }} >
                                                Más detalles
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} sx={{ mt: 2, mb: 2, }} >
                                            <Typography component="h1" variant="h5" color={'#fff'} style={{ fontSize: 14 }} >
                                                Apuestas ganadas: ${apuestasGanadas.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                {"Ganancia real (-10%)"}: ${gananciaReal.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Apuestas perdidas: ${apuestasPerdidas.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Apuestas actuales: ${totalCurrentBet.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Depositos aprobados: ${depositosAprov.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Retiros aprobados: ${withdrawalsApproved.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Retiros en espera: ${withdrawalsWaiting.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Saldo esperado: ${saldoEsperado.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1 }} style={{ fontSize: 14 }} >
                                                Saldo actual: ${saldo.toFixed(2)}
                                            </Typography>
                                            <Typography component="h1" variant="h5" color={'#fff'} sx={{ mt: 1, mb: 2 }} style={{ fontSize: 14 }} >
                                                Diferencia: ${(saldo.toFixed(2) - saldoEsperado.toFixed(2)).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    }
                    {tabUserDetails === 4 &&
                        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: "90%", mt: 3 }}>
                            <Grid item xs={12} md={12} sx={{ mt: 2 }} >
                                <Typography component="h1" variant="h5" color={'#fff'} sx={{ ml: 1 }} >
                                    Saldo actual: ${saldo.toFixed(2)}
                                </Typography>
                                <hr></hr>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ mt: 4, mb: 2 }} >
                                <CssTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="balance"
                                    label="Saldo actual"
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                                />
                            </Grid>
                            {alertMessage !== "" &&
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <Alert severity="error">{alertMessage}</Alert>
                                </Grid>}
                            {successMessage !== "" &&
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <Alert severity="success">{successMessage}</Alert>
                                </Grid>}
                            <Grid item xs={12} md={12} sx={{ mt: 2, }} >
                                <ButtonC
                                    onClick={(e) => handleAddMoney()}
                                    style={{
                                        width: "210px",
                                    }}
                                >
                                    AGREGAR
                                </ButtonC>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ mt: 2, }} >
                                <ButtonC
                                    onClick={(e) => handleSubtractMoney()}
                                    style={{
                                        backgroundColor: "#ff0000",
                                        width: "210px",
                                        borderColor: "#ff0000",
                                    }}>
                                    QUITAR
                                </ButtonC>
                            </Grid>
                        </Grid>
                    }
                    {tabUserDetails === 5 &&
                        <>
                            <TableContainer sx={{ mt: 5 }}>
                                <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>Autorización</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>Tipo de movimiento</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>Descripción</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>Cantidad</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>Cantidad anterior</TableCell>
                                            <TableCell align="center" sx={{ color: "white" }}>Cantidad Nueva</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {history.map((doc, index) => (
                                            <TableRow
                                                key={doc.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center" sx={{ color: "white" }}>
                                                    {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: "white" }}>{doc.authorizedUserName}</TableCell>
                                                <TableCell align="center" style={{ fontSize: "14px", color: '#fff' }}>{doc.movementType}</TableCell>
                                                <TableCell align="center" style={{ fontSize: "14px", color: '#fff' }}>{doc.description}</TableCell>
                                                {<TableCell align="center" sx={{ color: "white" }}>{doc.movement}</TableCell>}
                                                <TableCell align="center" sx={{ color: "white" }}>{doc.initialValue}</TableCell>
                                                <TableCell align="center" sx={{ color: "white" }}>{doc.newBalance}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                                <ButtonAnt
                                    disabled={currentPage === 1}
                                    style={{ background: '#1a1a1a', border: 'none', color: "#fff" }}
                                    onClick={() => handlePageChange(currentPage - 1)}>
                                    <LeftOutlined style={{ color: "#fff" }} />
                                </ButtonAnt>
                                <p style={{ color: "#fff" }}>{currentPage} de {Math.ceil(listSize / pagination.pageSize)}</p>
                                <ButtonAnt
                                    disabled={currentPage === Math.ceil(listSize / pagination.pageSize)}
                                    style={{ background: '#1a1a1a', border: 'none' }}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    <RightOutlined style={{ color: "#fff" }} />
                                </ButtonAnt>
                            </div>
                        </>
                    }
                </Box>
                <Box sx={{ mt: 10, mb: 5 }}>
                    <hr></hr>
                </Box>
            </Container>
        </div>
    )
};

export default UserDetails;
