import React, { useEffect, useState, useRef } from 'react';
import { dbFights, storage } from "../firebase";
import { collection, getDocs, query, orderBy, Timestamp, setDoc, doc, updateDoc } from "firebase/firestore";
import { Table, Button, Input, Space, Form, Modal, Radio, Upload, message, Alert, Spin } from "antd";
import { SearchOutlined, UploadOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import Typography from '@mui/material/Typography';
import './ADS.css';
import { makeStyles } from '@material-ui/core';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import Loading from './Loading';
import { useUserAuth } from "../context/UserAuthContext";

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "22px",
    fontWeight: "700",
    lineHeight: "16.8px",
    textAlign: "left"
  },
});

const CssTable = styled(Table)({
  '& .ant-table': {
    borderBottom: '1px solid #1F1F1F',
    borderRadius: "13px",
    background: "rgb(20, 20, 20)",
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  },
  '& .ant-table-thead > tr > th': {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16.8px",
    color: "#fff",
    background: "#1A1A1A",
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-container table > thead > tr:first-child th:first-child': {
    borderTopLeftRadius: "13px",
  },
  '& .ant-table-container table > thead > tr:first-child th:last-child': {
    borderTopRightRadius: "13px",
  },
  '& .ant-table-tbody > tr > td': {
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover': {
    background: "#1A1A1A",
  },
  '& .ant-pagination-item-active': {
    background: "rgb(20, 20, 20)",
    borderColor: "#000",
  },

  '& .ant-pagination-item-active a': {
    color: "#fff"
  },

  '& .ant-pagination-item a': {
    color: "#fff"
  },

  '& .ant-pagination-item': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#1F1F1F",

  },

  '& .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

  '& .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

});

const CssItem = styled(Form.Item)({
  '& .ant-form-item-label > label': {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    maxWidth: "100%",
    height: "32px",
    color: "#fff",
    fontSize: "14px"
  }
});

const CssRadius = styled(Radio)({
  '& .ant-radio-disabled + span': {
    color: "#fff",
  }
});

const CssUpload = styled(Upload)({
  '& .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item': {
    border: "1px solid rgb(56, 161, 105)",
    borderRadius: "13px"
  }
});

const ADS = () => {
  const [ads, setAds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [viewCompleteImage, setViewCompleteImage] = useState(null);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState({});
  const [fileComplete, setFileComplete] = useState({});
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [addSelected, setAddSelected] = useState(null);
  const inputSearch = useRef(null);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [currentCompleteImg, setCurrentCompleteImg] = useState(null);
  const { loadSpin, loadingComponent } = useUserAuth();
  const classes = useStyles();

  const getPromotions = async () => {
    // Activa el spinner antes de iniciar la consulta
    loadSpin(true);

    try {
      const promotionsCollectionRef = collection(dbFights, "Ads");
      const q = query(promotionsCollectionRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const items = [];
      querySnapshot.forEach((doc) => {
        const item = doc.data();
        items.push({
          ...item,
          id: doc.id,
        });
      });

      // Actualiza el estado con las promociones obtenidas
      setAds(items);
    } catch (error) {
      console.error("Error al obtener las promociones:", error);
    } finally {
      // Desactiva el spinner después de completar la consulta
      loadSpin(false);
    }
  };


  const onCancel = () => {
    form.resetFields();
    setShowModal(false);
    setIsViewing(false);
    setFile({});
    setFileComplete({});
    setViewCompleteImage(null);
    setViewImage(null);
    setAddSelected(null);
    setVisibleEdit(false);
    setCurrentBanner(null);
  };

  const onSubmitNew = () => {
    form.validateFields().then((values) => {
      onCreate(values);
    });
  };

  const onCreate = async (values) => {
    setLoading(true);
    const metadata = {
      contentType: "image/jpeg",
    };

    const id = uuidv4();

    const storageRef = ref(storage, `anuncios/${id}/anuncio.png`);
    const storageCompleteRef = ref(storage, `anuncios/${id}/anuncio_completo.png`);
    try {
      await uploadBytes(storageRef, file, metadata);
      const urlFile = await getDownloadURL(storageRef);
      await uploadBytes(storageCompleteRef, fileComplete, metadata);
      const urlFileComplete = await getDownloadURL(storageCompleteRef);
      const data = {
        name: values.name,
        description: values.description,
        isActive: values.isActive,
        urlMedia: urlFile,
        urlMediaComplete: urlFileComplete,
        createdAt: Timestamp.fromDate(new Date()),
      };
      await setDoc(doc(dbFights, "Ads", id), data);
      const newAds = [...ads];
      newAds.push(data);
      setAds(newAds);
      setShowModal(false);
      form.resetFields();
      setFile({});
      setFileComplete({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const handleBeforeUpload = (file, type) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 3;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 3MB!", 3);
      return Upload.LIST_IGNORE;
    }
    if (type === "banner") {
      setFile(file)
    } else {
      setFileComplete(file);
    }
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const viewAdd = (record) => {
    form.resetFields();
    setIsViewing(true);
    setViewImage(record.urlMedia);
    setViewCompleteImage(record.urlMediaComplete);
    form.setFieldsValue({
      name: record.name,
      description: record.description,
      isActive: record.isActive,
    });
    setShowModal(true);
  }

  const viewAddEdit = (record) => {
    formEdit.resetFields();
    setViewImage(record.urlMedia);
    setViewCompleteImage(record.urlMediaComplete);
    formEdit.setFieldsValue({
      name: record.name,
      description: record.description,
      isActive: record.isActive,
      banner: [{ url: record.urlMedia }],
      completeImg: [{ url: record.urlMediaComplete }],
    });
    setCurrentBanner([{ url: record.urlMedia }]);
    setCurrentCompleteImg([{ url: record.urlMediaComplete }]);
    setAddSelected(record);
    setVisibleEdit(true);
  };

  const onCancelEdit = () => {
    formEdit.resetFields();
    setAddSelected(null);
    setVisibleEdit(false);
    setFile({});
  };

  const onEditAdd = () => {
    formEdit.validateFields().then((values) => {
      editAdd(values);
    });
  };

  const editAdd = async (values) => {
    setLoading(true);
    const metadata = {
      contentType: "image/jpeg",
    };
    let data = {};
    if (Object.keys(file).length !== 0) {
      const storageRef = ref(storage, `anuncios/${addSelected.id}/anuncio.png`);
      await uploadBytes(storageRef, file, metadata);
      const urlFile = await getDownloadURL(storageRef);
      data.urlMedia = urlFile;
    }

    if (Object.keys(fileComplete).length !== 0) {
      const storageCompleteRef = ref(storage, `anuncios/${addSelected.id}/anuncio_completo.png`);
      await uploadBytes(storageCompleteRef, fileComplete, metadata);
      const urlFileComplete = await getDownloadURL(storageCompleteRef);
      data.urlMediaComplete = urlFileComplete;
    }

    const updatedData = {
      name: values.name,
      description: values.description,
      isActive: values.isActive,
      ...data,
    };

    try {
      await updateDoc(doc(dbFights, "Ads", addSelected.id), updatedData);
      const items = ads.filter((item) => item.id !== addSelected.id);
      const updateAdd = { ...addSelected, ...updatedData };
      items.push(updateAdd);
      setAds(items);
      setLoading(false);
      setAddSelected(null);
      setVisibleEdit(false);
      setFile({});
      setFileComplete({});
      formEdit.resetFields();
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <p style={{ paddingLeft: '5px', margin: 0 }}>{record.name}</p>
          </div>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              placeholder=""
              value={selectedKeys[0]}
              ref={inputSearch}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                key="btnFilter"
                type="primary"
                onClick={() => {
                  confirm();
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                key="btnCleaner"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: true });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.name != null) {
          return record.name.toLowerCase().includes(value.toLowerCase());
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => inputSearch.current?.focus(), 100);
        }
      },
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: "Opciones",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={() => viewAdd(record)}
            type="link"
            icon={<EyeOutlined style={{ color: "rgb(56, 161, 105)" }} />}
          >
          </Button>
          <Button
            onClick={() => viewAddEdit(record)}
            type="link"
            icon={<EditOutlined style={{ color: "#1890ff" }} />}
          >
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    getPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-body-ads-panel">
      {loadingComponent &&
        <Loading />
      }
      {!loadingComponent &&
        <div>
          <div className="container-users-actions">
            <div style={{ flex: 2 }}>
              <Typography component="h1" variant="h4" className={classes.typography} color={'#fff'} style={{ fontSize: "1.5rem", fontWeight: "normal" }} >
                Anuncios
              </Typography>
            </div>
            <AddCircleIcon variant="contained" color="primary" size="large" startIcon={<AddIcon />}
              onClick={() => { form.resetFields(); setShowModal(true); }}
              style={{
                color: "rgb(56, 161, 105)",
                fontSize: "40px",
                cursor: "pointer"
              }}>
            </AddCircleIcon>
          </div>
          <TableContainer component={Paper} style={{ borderRadius: 20, background: "rgb(14, 14, 14)", }}>
            <CssTable
              dataSource={ads}
              columns={columns}
              rowKey="id"
              scroll={{ x: '70vw' }}
            />
          </TableContainer>

          {/* Modal Add */}
          <Modal
            open={showModal}
            onOk={onSubmitNew}
            onCancel={onCancel}
            footer={null}
            centered
          >
            <Spin
              size="large"
              spinning={loading}
            >
              <>
                <h2 className={classes.typography} style={{ color: "#fff", fontWeight: "normal" }}>Información del anuncio</h2>
                <Form form={form} layout="vertical" name="form">
                  <CssItem
                    label="Nombre"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese el nombre",
                      },
                    ]}
                  >
                    <Input
                      style={{ backgroundColor: "#1a1a1a", borderColor: 'rgb(56, 161, 105)', color: "#fff", borderRadius: "5px", height: "40px" }}
                      className="inputRegister"
                      disabled={isViewing ? true : false}
                    />
                  </CssItem>
                  <CssItem
                    label="Descripción"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese la descripción",
                      },
                    ]}
                  >
                    <Input
                      style={{ backgroundColor: "#1a1a1a", borderColor: 'rgb(56, 161, 105)', color: "#fff", borderRadius: "5px", height: "40px" }}
                      disabled={isViewing ? true : false}
                    />
                  </CssItem>
                  <CssItem
                    label="Activo"
                    name="isActive"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione si el anuncio está activo",
                      },
                    ]}
                  >
                    <Radio.Group disabled={isViewing ? true : false}>
                      <CssRadius value={true} style={{ color: "#fff" }}>Si</CssRadius>
                      <CssRadius value={false} style={{ color: "#fff" }}>No</CssRadius>
                    </Radio.Group>
                  </CssItem>

                  <CssItem
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        required: true,
                        message: "Debe subir una foto de promoción",
                      },
                    ]}
                  >
                    {isViewing ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className={classes.typography} style={{ color: "#fff", fontWeight: "normal", fontSize: "18px", marginBottom: "1em" }}>Banner</span>
                        <img
                          alt="eventImage"
                          src={viewImage}
                          width="320px"
                          height="180px"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    ) : (
                      <CssUpload
                        beforeUpload={(file) => handleBeforeUpload(file, "banner")}
                        onRemove={() => setFile({})}
                        maxCount={1}
                        listType="picture"
                      >
                        <Button icon={<UploadOutlined />} style={{ borderRadius: "13px", height: "40px", background: "rgb(120, 17, 19)", borderColor: "#000", color: "#fff" }}>
                          Seleccione una foto para el banner
                        </Button>
                      </CssUpload>
                    )}
                  </CssItem>

                  <CssItem
                    name="uploadentire"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        required: true,
                        message: "Debe subir la foto completa de la promoción",
                      },
                    ]}
                  >
                    {isViewing ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className={classes.typography} style={{ color: "#fff", fontWeight: "normal", fontSize: "18px" }}>Foto completa</span>
                        <img
                          alt="eventImage"
                          src={viewCompleteImage}
                          width="200px"
                          height="180px"
                          style={{ objectFit: "contain" }}
                        />
                      </div>

                    ) : (
                      <CssUpload
                        beforeUpload={(file) => handleBeforeUpload(file, "complete")}
                        onRemove={() => setFileComplete({})}
                        maxCount={1}
                        listType="picture"
                      >
                        <Button icon={<UploadOutlined />} style={{ borderRadius: "13px", height: "40px", background: "rgb(120, 17, 19)", borderColor: "#000", color: "#fff" }}>
                          Seleccione la foto completa del anuncio
                        </Button>
                      </CssUpload>
                    )}
                  </CssItem>

                  {!isViewing ? (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                      <button key="submit" type="primary" onClick={onSubmitNew} className='btn-add-modal' >
                        Agregar
                      </button>
                      {/* <button onClick={onCancel} className='btn-add-cancel' style={{ marginLeft: "5px" }}>
                    Cancelar
                  </button> */}
                    </div>
                  ) : null
                  }
                  {error && (
                    <Alert
                      className="alertMessage"
                      message="Error"
                      description={error}
                      type="error"
                      showIcon
                      closable
                    />
                  )}
                </Form>
              </>
            </Spin>
          </Modal>

          {/* Modal Edit */}
          <Modal
            open={visibleEdit}
            onOk={onEditAdd}
            onCancel={onCancelEdit}
            footer={null}
          >
            <Spin
              size="large"
              spinning={loading}
            >
              <Form form={formEdit} layout="vertical" name="form_event">
                <CssItem
                  label="Nombre"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese el nombre",
                    },
                  ]}
                >
                  <Input
                    style={{ backgroundColor: "#1a1a1a", borderColor: 'rgb(56, 161, 105)', color: "#fff", borderRadius: "5px", height: "40px" }}
                    className="inputRegister"
                  />
                </CssItem>
                <CssItem
                  label="Descripción"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la descripción",
                    },
                  ]}
                >
                  <Input
                    style={{ backgroundColor: "#1a1a1a", borderColor: 'rgb(56, 161, 105)', color: "#fff", borderRadius: "5px", height: "40px" }}
                  />
                </CssItem>
                <CssItem
                  label="Activo"
                  name="isActive"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione si el anuncio está activo",
                    },
                  ]}
                >
                  <Radio.Group>
                    <CssRadius value={true} style={{ color: "#fff" }}>Si</CssRadius>
                    <CssRadius value={false} style={{ color: "#fff" }}>No</CssRadius>
                  </Radio.Group>
                </CssItem>
                <CssItem
                  name="banner"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Debe subir una foto de promoción",
                    },
                  ]}
                >
                  <CssUpload
                    beforeUpload={(file) => handleBeforeUpload(file, "banner")}
                    onRemove={() => setFile({})}
                    maxCount={1}
                    listType="picture"
                    defaultFileList={currentBanner}
                  >
                    <Button icon={<UploadOutlined />} style={{ borderRadius: "13px", height: "40px", background: "rgb(120, 17, 19)", borderColor: "#000", color: "#fff" }}>
                      Seleccione una foto para el banner
                    </Button>
                  </CssUpload>
                </CssItem>
                <CssItem
                  name="completeImg"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Debe subir la foto completa de la promoción",
                    },
                  ]}
                >
                  <CssUpload
                    beforeUpload={(file) => handleBeforeUpload(file, "complete")}
                    onRemove={() => setFileComplete({})}
                    maxCount={1}
                    listType="picture"
                    defaultFileList={currentCompleteImg}
                  >
                    <Button icon={<UploadOutlined />} style={{ borderRadius: "13px", height: "40px", background: "rgb(120, 17, 19)", borderColor: "#000", color: "#fff" }}>
                      Seleccione la foto completa del anuncio
                    </Button>
                  </CssUpload>
                </CssItem>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <button onClick={onCancel} className='btn-add-cancel'>
                    Cancelar
                  </button>
                  <button key="submit" type="primary" onClick={onEditAdd} className='btn-add-modal' style={{ marginLeft: "5px" }}>
                    Editar
                  </button>
                </div>
              </Form>
            </Spin>
          </Modal>
        </div>}
    </div>
  );
};

export default ADS;
