import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { useNavigate, Link } from 'react-router-dom';
import { Stack } from "@mui/material";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { dbRoulette } from "../firebase";
import { onSnapshot, collection, query, orderBy } from "firebase/firestore";
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { rouletteDescStatus } from "../utils/constants";
import { Button } from "./ui";
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  }
})

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
    background: "#1A1A1A",
  },
});

const CssTextField = styled(TextField)({
  width: "100%",
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
    background: '#1a1a1a'
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
      background: "#1a1a1a",
      borderColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
        background: "#1a1a1a"
      },
    }
  },
});

function RouletteList() {
  const navigate = useNavigate();
  const [roulettes, setRoulettes] = useState([]);
  const classes = useStyles();

  //===== PAGINATION ========================
  const pageSize = 30;
  const [sliceData, setSliceData] = useState([]);
  const [listSize, setListSize] = useState(5);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  });

  const handleChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;

    setPagination({ ...pagination, from: from, to: to });
  }

  const [search, setSearch] = useState("");

  //=============================================================

  const getRoulettesRealTime = async (from, to) => {
    const rouletteCollectionRef = collection(dbRoulette, "Roulette");
    const q = query(rouletteCollectionRef, orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });
      setRoulettes(items);
      setSliceData(items.slice(from, to));
      setListSize(items.length);
    });
    return () => unsubscribe();
  }

  const dateOptions = {
    month: "long",
    day: "numeric",
    year: 'numeric'
  };

  useEffect(() => {
    getRoulettesRealTime(pagination.from, pagination.to).then(response => {
      setPagination({ ...pagination, count: listSize });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.from, pagination.to]);

  return (
    <Container component="main" width="100%" style={{ paddingBottom: "2em" }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Stack
          sx={{ mt: 5, mb: 3 }}
          direction="row"
          spacing={5}
          justifyContent="flex-start"
        >
          <Typography component="h1" variant="h4" color={'#fff'} align="left">
            Ruleta
          </Typography>
          <Button
            style={{width: 'auto'}}
            onClick={(e) => navigate("/crear-rifa-ruleta")}
          >
            Nueva rifa
          </Button>
          <CssTextField
            id="busqueda"
            variant="outlined"
            placeholder="Búsqueda"
            sx={{ width: { sm: "50px", md: "300px", lg: "320px" } }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
      </Box>
      <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)" }}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
          {search.toLowerCase() === "" ?
            <TableBody>
              {sliceData.map((doc, index) => (
                <TableRowStyled
                  key={doc.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    <Box
                      component="img"
                      alt="Imagen previa"
                      src="https://www.casino.es/imagenes/juegos/ruleta/hero-ruleta.svg"
                      style={{ width: "70px", height: "70px", marginLeft: "8px", borderRadius: 14, }}
                    />
                  </TableCell>
                  <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography} >{doc.rouletteName}</TableCell>
                  <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography} >{doc.description}</TableCell>
                  <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography} >
                    {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                  </TableCell>
                  <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography} >{rouletteDescStatus[doc.status]}</TableCell>
                  <TableCell align="center">
                    <Grid container spacing={2} direction="row" justifyContent="center" >
                      <Grid item xs={4} md={4} lg={4}>
                        <Link to={`/rouletteview-admin/${doc.id}`}>
                          <VisibilityIcon
                            sx={{ fontSize: "20px", color: "#EA9F26", }}
                            style={{ backgroundColor: "#FFFFFF", borderRadius: 24, padding: "0px", textDecoration: 'none' }}
                          >
                          </VisibilityIcon>
                        </Link>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <Link to={`/editar-ruleta/${doc.id}/${doc.status}`}>
                          <EditIcon 
                            sx={{ fontSize: "20px", color: "#EA9F26", }}
                            style={{ backgroundColor: "#FFFFFF", borderRadius: 24, padding: "0px", }}
                          >
                          </EditIcon>
                        </Link>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRowStyled>
              ))}
            </TableBody>
            :
            <TableBody>
              {roulettes
                .filter((item) => {
                  return search.toLowerCase() === "" ? item
                    : item.rouletteName.toLowerCase().includes(search);
                })
                .map((doc, index) => (
                  <TableRowStyled
                    key={doc.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      <Box
                        component="img"
                        alt="Imagen previa"
                        src="https://www.casino.es/imagenes/juegos/ruleta/hero-ruleta.svg"
                        style={{ width: "70px", height: "70px", marginLeft: "8px", borderRadius: 14, }}
                      />
                    </TableCell>
                    <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography}>{doc.rouletteName}</TableCell>
                    <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography}>{doc.description}</TableCell>
                    <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography}>
                      {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                    </TableCell>
                    <TableCell align="center" component={Link} to={`/rouletteview-admin/${doc.id}`} className={classes.typography} >{rouletteDescStatus[doc.status]}</TableCell>
                    <TableCell align="center">
                      <Grid container spacing={2} direction="row" justifyContent="center" >
                        <Grid item xs={4} md={4} lg={4}>
                          <Link to={`/rouletteview-admin/${doc.id}`}>
                            <VisibilityIcon
                              sx={{ fontSize: "20px", color: "#EA9F26", }}
                              style={{ backgroundColor: "#1D1D1B", borderRadius: 24, padding: "10px 10px", }}
                              component={Link}
                              to={`/rouletteview-admin/${doc.id}`}
                            >
                            </VisibilityIcon>
                          </Link>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                        <Link to={`/rouletteview-admin/${doc.id}`}>
                          <EditIcon 
                            sx={{ fontSize: "20px", color: "#EA9F26", }}
                            style={{ backgroundColor: "#FFFFFF", borderRadius: 24, padding: "0px", }}
                          >
                          </EditIcon>
                        </Link>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRowStyled>
                ))}
            </TableBody>}
        </Table>
      </TableContainer>
      {search.toLowerCase() === "" ?
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 8 }} >
          <Grid item xs={12} sm={12} md={12} alignItems="center" justifyContent="center"  >
            <Pagination count={Math.ceil(listSize / pageSize)}
              onChange={handleChange}
              color="primary"
              sx={{ mb: 2, mt: -2 }}
            />
          </Grid>
        </Grid> :
        <></>}
      <Box sx={{ mt: 10, mb: 5 }}>
        <hr></hr>
      </Box>
    </Container>
  );
};

export default RouletteList;
