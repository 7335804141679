import React, { useState } from "react";
import { List, Avatar, Button, Modal, Input } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsForm from "./NotificationsForm";
import { useUserAuth } from "../context/UserAuthContext";
import { deleteDoc, doc } from "firebase/firestore";
import { firestore } from '../firebase'


import './Notifications.css'
import styled from "styled-components";



const useStyles = makeStyles({
    containerList: {
        maxWidth: "980px",
        background: "rgb(14, 14, 14)",
        margin: "20px auto",
        padding: "2em",
        borderRadius: "21px",
    },
})

const { confirm } = Modal;

const ButtonYes = styled.button`
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  color: white !important;
  
  &:hover {
    background-color: #388e3c !important;
    border-color: #388e3c !important;
  }
`;

const ButtonNo = styled.button`
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: white !important;
  
  &:hover {
    background-color: #d32f2f !important;
    border-color: #d32f2f !important;
  }
`;

const NotificationsList = ({ value }) => {
    const [searchText, setSearchText] = useState("");
    const [dataNotification, setDataNotification] = useState([])
    const { setOpenModal, setStateModal } = useUserAuth();
    const classes = useStyles();
    // Eliminar una notificación
    const handleDelete = (id) => {
        confirm({
            title: <span style={{ color: "#fff" }}>¿Estás seguro de que quieres eliminar esta notificación?</span>,
            okText: "Sí",
            cancelText: "No",
            className: "custom-confirm",
            onOk() {
                const msgDoc = doc(firestore, "Notifications", id);
                return deleteDoc(msgDoc);
            },
            okButtonProps: {
                children: <ButtonYes>Sí</ButtonYes>,
            },
            cancelButtonProps: {
                children: <ButtonNo>No</ButtonNo>,
            }
        });
    };

    // Abrir modal de edición
    const handleEdit = (e) => {
        setOpenModal(true);
        setStateModal("editing")
        setDataNotification(e)
    };

    const filteredData = value.filter(
        (item) =>
            item.status === true &&
            (item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                item.description.toLowerCase().includes(searchText.toLowerCase()))
    );

    const sendNotification = (e) => {
        setOpenModal(true);
        setStateModal("confirmation")
        setDataNotification(e)
    };

    return (
        <div className={classes.containerList}>
            <h2 style={{ color: "#fff" }}>Lista de Notificaciones</h2>

            {/* Buscador */}
            <Input
                placeholder="Buscar notificaciones"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                    marginBottom: "20px",
                    borderRadius: "10px",
                    backgroundColor: "rgb(20, 20, 20)",
                    color: "#fff",
                    border: 0,
                    padding: "1em"
                }}
            />

            <List
                itemLayout="horizontal"
                dataSource={filteredData}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Button
                                type="link"
                                icon={<EditIcon />}
                                onClick={() => handleEdit(item)}
                            />,
                            <Button
                                type="link"
                                danger
                                icon={<DeleteIcon />}
                                onClick={() => handleDelete(item.id)}
                            />,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    icon={<NotificationOutlined style={{ color: "#000" }} />}
                                    onClick={() => sendNotification(item)}
                                    style={{ cursor: "pointer" }}
                                />
                            }
                            title={<span style={{ color: "#fff" }}>{item.title}</span>}
                            description={<span style={{ color: "#fff" }}>{item.description}</span>}
                        />
                    </List.Item>
                )}
            />
            <NotificationsForm dataNotification={dataNotification} />
        </div>
    );
};

export default NotificationsList;
