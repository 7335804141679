import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { collection, query, where, doc, onSnapshot, limit, getDocs, orderBy } from "firebase/firestore";
import { getAuth, getIdToken } from "firebase/auth";
import { firestore } from "../../firebase";
import { ChatEvent, ChatIframeEvent } from "../../components/event/index";
// import SportsMmaIcon from '@mui/icons-material/SportsMma';
// import RecommendIcon from '@mui/icons-material/Recommend';
// import NumbersIcon from '@mui/icons-material/Numbers';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MessageOutlined from "@mui/icons-material/MessageOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { Button, CardMakeBet, Table as TableComp } from "../../components/ui";
import { useUserAuth } from "../../context/UserAuthContext";
import { Modal, Alert, Spin, notification } from "antd";
import styled from "styled-components";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { FullscreenOutlined, FullscreenExitOutlined } from "@mui/icons-material";
import '../fights/Fight.css';

const ModalTitle = styled.h3`
  color: #fff;
`;

const Label = styled.p`
  color: #fff;
  margin: 0;
`;

const BtnRed = styled.button`
  background-color: transparent;
  color: #E53E3E;
  border: 1px solid #E53E3E;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  &[data-selected="true"] {
    background-color: #E53E3E;
    color: #fff;
  }
`;

const BtnGreen = styled.button`
  background-color: transparent;
  color: #38A169;  
  border: 1px solid #38A169;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  &[data-selected="true"] {
    background-color: #38A169;
    color: #fff;
  }
`;

const TextInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #1C1C1C;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  max-width: 250px;
`;

const Event = () => {
  const [loading, setLoading] = useState(false);
  const paramsIdEvent = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const cardMakeBetRef = useRef();

  const [bets, setBets] = useState([]);
  const [userBets, setUserBets] = useState([]);
  const [userBalance, setUserBalance] = useState(0);
  const [activeLive, setActiveLive] = useState(false);
  const [chatActive, setChatActive] = useState(false);

  const [selectedBet, setSelectedBet] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [editedBet, setEditedBet] = useState(null);
  const [showEditBetError, setShowEditBetError] = useState(false);
  const [dateBets, setDateBets] = useState(false);

  const [showModalHunt, setShowModalHunt] = useState(false);
  const [betToHunt, setBetToHunt] = useState(null);

  const [totalGreen, setTotalGreen] = useState(0);
  const [totalRed, setTotalRed] = useState(0);

  const [showMakeBetError, setShowMakeBetError] = useState(false);
  const [message, setMessage] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [event, setEvent] = useState(null);
  const [eventStatus, setEventStatus] = useState(true);
  const [nextEventLive, setNextEventLive] = useState("");
  const [cancelRedirect, setCancelRedirect] = useState(false);

  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showChatIframe, setShowChatIframe] = useState(false);
  const [showMyBets, setShowMyBets] = useState(false);
  const [showAllBets, setShowAllBets] = useState(false);
  const [showCreateBet, setShowCreateBet] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [noCredit, setNoCredit] = useState(false);

  const getBets = async () => {
    const betsCollectionRef = collection(firestore, "EventsBets");
    const q = query(betsCollectionRef, where("uidEvent", "==", paramsIdEvent.idEvent));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      const betsV = [];
      const betsR = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
        if (doc.data().idTeam === "1") {
          betsV.push({ ...doc.data(), id: doc.id });
        }
        if (doc.data().idTeam === "2") {
          betsR.push({ ...doc.data(), id: doc.id });
        }
      });
      setBets(bet);
      if (bet.length !== 0) {
        setTotalGreen((betsV.reduce((a, v) => a = a + v.bet, 0)))
        setTotalRed((betsR.reduce((a, v) => a = a + v.bet, 0)))
      }

    });
    return () => unsubscribe();
  }

  const getUserData = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Users", user.uid), (doc) => {
      if(doc.data().dateBalance){
        const currentDate = new Date()
        const date = doc.data().dateBalance.toDate()
        const dif = currentDate.getTime() - date.getTime()
        const days = dif / (1000 * 60 * 60 * 24)
        if (days >= 1) {
          setNoCredit(true)
        }
      }
      setUserBalance(doc.data().balance);
      setActiveLive(doc.data().activeLive);
    });
    return () => unsubscribe();
  }

  const getEventData = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Events", paramsIdEvent.idEvent), (doc) => {
      setEvent(doc.data());
      setTeam1(doc.data().teams[0].name);
      setTeam2(doc.data().teams[1].name);
      setEventStatus(doc.data().status);
      if(doc.data().chat !== null) {
        setChatActive(doc.data().chat);
      } else {
        setChatActive(true);
      }
    });
    return () => unsubscribe();
  }

  const validateUserBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const queryBets = query(betsCollectionRef, where("uidUser", "==", user.uid), orderBy("date", "desc"), limit(1));
    const snapshot = await getDocs(queryBets);
    const bets = [];
    snapshot.forEach((doc) => {
      bets.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() });
    });
    const betsEventCollectionRef = collection(firestore, "EventsBets");
    const queryEventBets = query(betsEventCollectionRef, where("uidUser", "==", user.uid), orderBy("date", "desc"), limit(1));
    const snapshotEvent = await getDocs(queryEventBets);
    snapshotEvent.forEach((doc) => {
      bets.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() });
    });

    const mostRecentBet = bets.reduce((latest, bet) => {
      const latestDate = new Date(latest.date);
      const currentDate = new Date(bet.date);
      return latestDate > currentDate ? latest : bet;
    });

    const currentDate = new Date()
    const date = mostRecentBet.date;
    const dif = currentDate.getTime() - date.getTime()
    const days = dif / (1000 * 60 * 60 * 24)

    if (days >= 21) {
      setDateBets(true)
    } else {
      setDateBets(false)
    }
  }

  const getUserBets = async () => {
    const betsCollectionRef = collection(firestore, "EventsBets");
    const q = query(betsCollectionRef, where("uidEvent", "==", paramsIdEvent.idEvent), where("uidUser", "==", user.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
      });
      setUserBets(bet);
    });
    return () => unsubscribe();
  }

  const searchEventLive = async () => {
    const ref = collection(firestore, "Events");
    const q = query(ref, where("live", "==", true), limit(1));

    const item = [];
    let isEventActive = false;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push({ ...doc.data(), id: doc.id });
    });
    if (item.length === 0) {
      return;
    }
    item.forEach((data, index) => {
      if (data.id !== paramsIdEvent.idEvent && data.status === true) {
        setNextEventLive(data.id);
        isEventActive = true;
      }
    });

    if (isEventActive) {
      handleOpen();
    }
  }

  const handleCancelRedirect = () => {
    setCancelRedirect(true);
    handleClose();
    setNextEventLive(paramsIdEvent.idEvent);
  }

  useEffect(() => {
    getBets();
    getUserBets();
    getUserData();
    getEventData();
    validateUserBets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [countDown, setCountDown] = useState(10);
  const timerId = useRef();

  useEffect(() => {
    if (open) {
      timerId.current = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000)
      return () => clearInterval(timerId.current);
    }
  }, [open]);

  useEffect(() => {
    if (countDown <= 0 && !cancelRedirect) {
      clearInterval(timerId.current);
      navigate("/event-view/" + nextEventLive);
      window.location.reload(false);
    }
    if (countDown === -2) {
      navigate("/event-view/" + nextEventLive);
      window.location.reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  useEffect(() => {
    if (!eventStatus) {
      searchEventLive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStatus]);

  const handleEdit = (bet) => {
    setSelectedBet(bet);
    setEditedBet(bet.bet);
    setShowModal(true);
  }

  const handleCloseEdit = () => {
    setShowModal(false);
    setSelectedBet(null);
    setEditedBet(null);
    setSelectedTeam(null);
    setShowEditBetError(false);
  };

  const handleMakeBet = async (amount, team) => {
    const teamId = team === team1 ? "1" : "2";
    setShowMakeBetError(false);
    if (amount < 50) {
      setShowMakeBetError(true);
      setMessage("La apuesta debe ser mayor o igual a 50");
      return;
    }
    if (userBalance < amount) {
      setShowMakeBetError(true);
      setMessage("Saldo insuficiente");
      return;
    }
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/event-create-bet`, {
        method: "POST",
        body: JSON.stringify({ eventId: paramsIdEvent.idEvent, teamId, bet: amount }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        setShowMakeBetError(true);
        setMessage(data.message);
        setLoading(false);
        return;
      }
      if (cardMakeBetRef.current) {
        cardMakeBetRef.current.reset();
      }
      notification.success({
        message: "Apuesta realizada",
        placement: "bottomRight"
      });
      setShowCreateBet(false);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setShowMakeBetError(true);
      setMessage(error.message);
      setLoading(false);
    }
  }

  const handleEditBet = async () => {
    if (selectedBet.bet === editedBet && selectedBet.idTeam === selectedTeam) {
      setShowEditBetError(true);
      setMessage("No se han realizado cambios");
      return;
    }
    if (userBalance < editedBet) {
      setShowEditBetError(true);
      setMessage("Saldo insuficiente");
      return;
    }
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/event-edit-bet`, {
        method: "POST",
        body: JSON.stringify({ betId: selectedBet.id, teamId: selectedTeam, bet: editedBet }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        setShowEditBetError(true);
        setMessage(data.message);
        setLoading(false);
        return;
      }
      handleCloseEdit();
      setLoading(false);
      notification.success({
        message: "Apuesta editada",
        placement: "bottomRight"
      });
    } catch (error) {
      console.error("Error: ", error)
      setShowEditBetError(true);
      setMessage(error?.message);
      setLoading(false);
    }
  };

  const huntBet = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/event-hunt-bet`, {
        method: "POST",
        body: JSON.stringify({ betId: betToHunt.id, teamId: betToHunt.idTeam, bet: betToHunt.bet }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        notification.error({
          message: "Error",
          description: data.message,
          placement: "bottomRight"
        });
        setLoading(false);
        return;
      }
      notification.success({
        message: "Apuesta cazada",
        placement: "bottomRight"
      });
      handleCloseHunt();
      setLoading(false);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: error?.message,
        placement: "bottomRight"
      });
      setLoading(false);
    }
  };

  const handleHuntBet = async (values) => {
    if (userBalance < values.bet) {
      notification.error({
        message: "Saldo insuficiente",
        description: "Tu saldo es insuficiente para realizar esta apuesta",
        placement: "bottomRight"
      });
      return;
    }
    const bet = {
      ...values,
      idTeam: values.idTeam === "1" ? "2" : "1",
      teamName: values.idTeam === "1" ? team2 : team1,
    };
    setSelectedBet(bet);
    setBetToHunt(values);
    setShowModalHunt(true);
  };

  const handleCloseHunt = () => {
    setShowModalHunt(false);
    setSelectedBet(null);
    setBetToHunt(null);
  };

  const columnsUserBets = [
    {
      title: 'Equipo',
      dataIndex: 'teamName',
      key: 'teamName',
    },
    {
      title: 'Apuesta',
      dataIndex: 'bet',
      key: 'bet',
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      render: (_id, item) => {
        if (item.status === 'aceptada' || !event?.betStatus) {
          return null
        } else {
          return <Button style={{ height: '30px', padding: '2px' }} onClick={() => handleEdit(item)}>Editar</Button>
        }
      }
    },
  ];

  const columnsUsersBets = [
    {
      title: 'Equipo',
      dataIndex: 'teamName',
      key: 'teamName',
    },
    {
      title: 'Apuesta',
      dataIndex: 'bet',
      key: 'bet',
    },
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      render: (_v, doc, index) => {
        if (!doc.isBetHunt && (doc.uidUser !== user.uid) && event?.betStatus) {
          return <Button style={{ height: '30px', padding: '2px' }} onClick={() => handleHuntBet(doc)}>Cazar</Button>
        } else {
          return <p style={{ margin: '0px' }}>{doc.uidUser === user.uid && "Tu apuesta"}{doc.uidBetHunt === user.uid && "Apuesta cazada"}</p>
        }
      }
    },
  ];

  return (
    <div className="container">
      {/* Frame */}
      <div className="contaier-body">
        <div>
          {event?.live && activeLive === true && dateBets === false ?
            <div style={{ position: "relative", paddingBottom: "56.25%", overflow: "hidden", height: "100%", maxWidth: "100%" }}>
              <iframe
                id={event?.idVideo}
                src={event?.urlVideo}
                type="application/x-mpegURL"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                allow="autoplay;encrypted-media"
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                oallowfullscreen="true"
                msallowfullscreen="true"
                style={{ position: "absolute", top: "0", left: "0" }}
                title='Live'>
              </iframe>

              {fullScreen && (
                <>
                  {event?.betStatus && (
                    <Button
                      onClick={() => setShowCreateBet(!showCreateBet)}
                      style={{
                        position: "absolute",
                        right: "186px",
                        top: "10px",
                        width: "auto",
                        padding: "2px",
                        height: "27px",
                        fontSize: "13px",
                        zIndex: 1000,
                      }}
                    >
                      {showCreateBet ? "Cerrar" : "Crear Apuesta"}
                    </Button>
                  )}
                  
                  <Button
                    onClick={() => setShowAllBets(!showAllBets)}
                    style={{
                      position: "absolute",
                      right: "125px",
                      top: "10px",
                      width: "auto",
                      padding: "2px",
                      height: "27px",
                      fontSize: "13px",
                      zIndex: 1000,
                    }}
                  >
                    {showAllBets ? "Cerrar" : "Apuestas"}
                  </Button>
                  <Button
                    onClick={() => setShowMyBets(!showMyBets)}
                    style={{
                      position: "absolute",
                      right: "40px",
                      top: "10px",
                      width: "auto",
                      height: "27px",
                      padding: "2px",
                      fontSize: "13px",
                      zIndex: 1000,
                    }}
                  >
                    {showMyBets ? "Cerrar" : "Mis Apuestas"}
                  </Button>
                  <MessageOutlined
                    style={{ position: "absolute", top: "10px", right: "10px", color: "white", cursor: "pointer" }}
                    onClick={() => setShowChatIframe(!showChatIframe)}
                  />
                </>
              )}
              <ChatIframeEvent visible={showChatIframe} toggleChat={() => setShowChatIframe(!showChatIframe)} active={chatActive}/>
              {showCreateBet && (
                <div style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <CardMakeBet
                    title='Elige un equipo'
                    amounts={[100, 200, 500, 1000]}
                    team1={team1}
                    team2={team2}
                    handleMakeBet={handleMakeBet}
                  >
                    {showMakeBetError && (
                      <Alert
                        message={message}
                        type="error"
                        showIcon
                      />
                    )}
                  </CardMakeBet>
                </div>
              )}

              {showMyBets && (
                <div style={{
                  position: 'absolute',
                  top: '25px',
                  right: '10px',
                  maxHeight: '300px',
                  width: '240px',
                }}>
                  <TableContainer
                    component={Paper}
                    sx={{ mt: 2, maxHeight: "200px", maxWidth: "240px", backgroundColor: "#1A1A1A" }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 25, padding: '2px' }}>Equipo</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 25, padding: '4px' }}>Apuesta</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 40, padding: '4px' }}>Estatus</TableCell>
                          {event?.betStatus &&
                            <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 30, padding: '4px' }}>Editar</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userBets.map((doc, index) => (
                          <TableRow key={doc.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {doc.idTeam === "1" &&
                              <TableCell align="center" sx={{ maxWidth: 25, border: 0 }} style={{ fontSize: "13px", color: "#33cc33", fontWeight: "bold", padding: '5px' }} >
                                {doc.teamName}
                              </TableCell>
                            }
                            {doc.idTeam === "2" &&
                              <TableCell align="center" sx={{ maxWidth: 25, border: 0 }} style={{ fontSize: "13px", color: "#ff0000", fontWeight: "bold", padding: '5px' }} >
                                {doc.teamName}
                              </TableCell>
                            }
                            <TableCell align="center" sx={{ maxWidth: 35, border: 0 }} style={{ fontSize: "12px", color: "white", padding: '5px' }}>${doc.bet.toFixed(2)}</TableCell>
                            <TableCell align="center" sx={{ maxWidth: 40, border: 0 }} style={{ fontSize: "12px", color: "white", padding: '5px' }}>{doc.status}</TableCell>
                            {event?.betStatus &&
                              <TableCell align="center" sx={{ maxWidth: 30, border: 0, paddingRight: 4 }} style={{ fontSize: "12px", padding: '5px' }}>
                                {!doc.isBetHunt && (
                                  <Button
                                    style={{
                                      color: "#FFFFFF",
                                      fontSize: "12px",
                                      width: "auto",
                                      padding: "2px 5px 2px 5px",
                                    }}
                                    onClick={(e) => handleEdit(doc)}>
                                    Editar
                                  </Button>
                                )}
                              </TableCell>}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              {showAllBets && (
                <div style={{
                  position: 'absolute',
                  top: '25px',
                  right: '10px',
                  maxHeight: '300px',
                  width: '230px',
                }}>
                  <TableContainer component={Paper} sx={{ mt: 2, maxHeight: "200px", maxWidth: "230px", backgroundColor: "#1A1A1A" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 20, padding: '0px' }}>Equipo</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 30, padding: '4px' }}>Apuesta</TableCell>
                          <TableCell align="center" sx={{ backgroundColor: "#1A1A1A", color: "white", maxWidth: 45, padding: '4px' }}>Opciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bets.map((doc, index) => (
                          <TableRow key={doc.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {doc.idTeam === "1" && (
                              <>
                                <TableCell align="center" style={{ color: "#33cc33", border: 0, maxWidth: 20, padding: '5px', fontSize: "13px", }}>{doc.teamName}</TableCell>
                                <TableCell align="center" style={{ color: "#33cc33", border: 0, maxWidth: 30, padding: '5px', fontSize: "13px", }}>${doc.bet.toFixed(2)}</TableCell>
                                {!doc.isBetHunt && (doc.uidUser !== user.uid) && event?.betStatus ? (
                                  <TableCell align="center" style={{ color: "#33cc33", border: 0, maxWidth: 45, padding: '5px' }}>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        width: "auto",
                                        height: "auto",
                                        padding: "4px 7px 4px 7px",
                                      }}
                                      onClick={() => handleHuntBet(doc)}
                                    >
                                      Cazar
                                    </Button>
                                  </TableCell>
                                ) :
                                  <TableCell align="center" style={{ color: "white", border: 0, maxWidth: 45, padding: '5px', fontSize: "12px" }}>
                                    {doc.uidUser === user.uid && "Tu apuesta"}
                                    {doc.uidBetHunt === user.uid && "Apuesta cazada"}
                                  </TableCell>
                                }
                              </>
                            )}

                            {doc.idTeam === "2" && (
                              <>
                                <TableCell align="center" style={{ color: "#ff0000", border: 0, maxWidth: 20, padding: '5px', fontSize: "13px" }}>{doc.teamName}</TableCell>
                                <TableCell align="center" style={{ color: "#ff0000", border: 0, maxWidth: 30, padding: '5px', fontSize: "13px" }}>${doc.bet.toFixed(2)}</TableCell>
                                {!doc.isBetHunt && (doc.uidUser !== user.uid) && event?.betStatus ? (
                                  <TableCell align="center" style={{ color: "#ff0000", border: 0, padding: '5px', maxWidth: 45, }}>
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        width: "auto",
                                        height: "auto",
                                        padding: "4px 7px 4px 7px",
                                      }}
                                      onClick={() => handleHuntBet(doc)}
                                    >
                                      Cazar
                                    </Button>
                                  </TableCell>
                                ) :
                                  <TableCell align="center" style={{ color: "white", border: 0, padding: '5px', fontSize: "12px", maxWidth: 45, }}>
                                    {doc.uidUser === user.uid && "Tu apuesta"}
                                    {doc.uidBetHunt === user.uid && "Apuesta cazada"}
                                  </TableCell>
                                }
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div> :
            <Box sx={{
              display: 'flex',
              backgroundColor: "black",
              height: {
                xs: 220,
                md: 420,
                lg: 550
              },
              flexDirection: 'column',
              alignItems: 'center',
            }}
              style={{ borderRadius: 20 }}>
              <Typography component="h1" variant="h5" align="center" sx={{ mt: 4 }} style={{ fontSize: "20px", color: 'white', fontWeight: "normal" }}>
                Transmisión apagada
              </Typography>
              {dateBets &&
                <h3 style={{ margin: 0, color: '#fff'}}>
                  Tienes varios días sin apostar, es necesario realizar por lo menos una apuesta para poder seguir disfrutando de las transmisiones en vivo en XTREMO
                </h3>
              }
              {noCredit &&
                <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold", marginBottom: "1em" }}>
                  Han pasado las 24 horas de acceso gratuito a las transmisiones en vivo. Para seguir disfrutando de nuestro streaming, debes tener al menos $100.00 en tu cuenta.
                </Typography>
              }
            </Box>
          }
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <h1 style={{ margin: 0, color: '#fff', flexGrow: 1, textAlign: 'center' }}>{event?.eventName}</h1>
            {fullScreen ? (
              <IconButton
                style={{
                  color: '#ffffff',
                  width: '30px',
                  height: '30px',
                  marginRight: '0px',
                  marginLeft: '0px'
                }}
                variant="contained"
                onClick={() => setFullScreen(false)}
              >
                <FullscreenExitOutlined style={{ color: "white", height: "30px", width: "30px" }} />
              </IconButton>
            ) : (
              <IconButton
                style={{
                  color: '#ffffff',
                  width: '30px',
                  height: '30px',
                  marginRight: '0px',
                  marginLeft: '0px'
                }}
                variant="contained"
                onClick={() => setFullScreen(true)}
              >
                <FullscreenOutlined style={{ color: "white", height: "30px", width: "30px" }} />
              </IconButton>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {dateBets &&
              <h3 style={{ margin: 0, color: '#fff'}}>
                Tienes varios días sin apostar, es necesario realizar por lo menos una apuesta para poder seguir disfrutando de las  transmisiones en vivo en XTREMO
              </h3>
            }
            {event?.winner !== "" &&
              <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold" }}>
                (Evento finalizado)
              </Typography>
            }
          </div>
          {!eventStatus && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component="h1" variant="h5"
                style={{ textAlign: "center", fontSize: "18px", color: '#ffffff' }}>
                ¡El evento ha terminado!
              </Typography>
              <Typography component="h1" variant="h5" sx={{ mt: 0.5, pb: 0.5 }}
                style={{ textAlign: "center", fontSize: "18px", color: '#ffffff' }}>
                El lado ganador es
              </Typography>
              {event?.winner === "1" &&
                <Box align="center" sx={{ mt: 1 }}
                  style={{
                    backgroundColor: "#50AF48",
                    borderRadius: 6,
                    padding: "12px 20px",
                    fontSize: "12px"
                  }}>
                  <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                    style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                    {event?.winner === "1" ? team1 : team2}
                  </Typography>
                </Box>
              }
              {event?.winner === "2" &&
                <Box align="center" sx={{ mt: 1 }}
                  style={{
                    backgroundColor: "#D32F2F",
                    borderRadius: 6,
                    padding: "12px 20px",
                    fontSize: "12px"
                  }}>
                  <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                    style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                    {event?.winner === "1" ? team1 : team2}
                  </Typography>
                </Box>
              }
              {event?.winner === "Empate" &&
                <Box align="center" sx={{ mt: 1 }}
                  style={{
                    backgroundColor: "#1D1D1B",
                    borderRadius: 6,
                    padding: "12px 20px",
                    fontSize: "12px"
                  }}>
                  <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                    style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                    {event?.winner}
                  </Typography>
                </Box>
              }
            </div>
          )}
          {event?.betCount === true &&
          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
              <h3 style={{ margin: 0, color: '#33cc33'}}>Total en {team1}: ${totalGreen.toFixed(2)}</h3>
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
              <h3 style={{ margin: 0, color: '#ff0000'}}>Total en {team2}: ${totalRed.toFixed(2)}</h3>
            </div>            
          </div>}
        </div>

        <div className="container-info-fights">
          {/* <CardInfo title='Peleas obligatorias:' content={event?.mandatoryFights} icon={<SportsMmaIcon style={{ color: "rgb(232, 26, 26)" }} />} />
          <CardInfo title='Partidos confirmados:' content={event?.confirmedGames} icon={<RecommendIcon style={{ color: "rgb(0 175 31)" }} />} />
          <CardInfo title='Número de gallos:' content={event?.amountRoosters} icon={<NumbersIcon style={{ color: "rgb(26 59 232)" }} />} />
          <CardInfo title='Costo de entrada:' content={`$ ${event?.amountCover ?? ''}`} icon={<MonetizationOnIcon style={{ color: "rgb(179, 84, 38)" }} />} /> */}
        </div>

        {!event?.betStatus && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
            <h3 style={{ color: '#EA9F26', textAlign: 'center' }}>Las apuestas se han detenido.</h3>
          </div>
        )}

        {!fullScreen && (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
              <div>
                <h3 style={{ color: '#fff', textAlign: 'center' }}>MIS APUESTAS</h3>
                <TableComp columns={columnsUserBets} data={userBets} />
              </div>
              <div>
                <h3 style={{ color: '#fff', textAlign: 'center' }}>TODAS LAS APUESTAS</h3>
                <TableComp columns={columnsUsersBets} data={bets} />
              </div>
            </div>
            {event?.betStatus && (
              <div style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                <CardMakeBet
                  ref={cardMakeBetRef}
                  title='Elige un color'
                  amounts={[100, 200, 500, 1000]}
                  team1={team1}
                  team2={team2}
                  handleMakeBet={handleMakeBet}
                >
                  {showMakeBetError && (
                    <Alert
                      message={message}
                      type="error"
                      showIcon
                    />
                  )}
                </CardMakeBet>
              </div>
            )}
            
            <div style={{paddingTop: '1rem', height: '300px'}}>
              <ChatEvent active={chatActive}/>
            </div>
          </>
        )}
      </div>

      {/* Modal Edit Bet */}
      <Modal
        closable={true}
        open={showModal}
        onCancel={handleCloseEdit}
        footer={null}
      >
        <div>
          <ModalTitle>Estas editando tu apuesta</ModalTitle>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Label>Equipo: <b style={{ color: selectedBet?.idTeam === "1" ? "#38A169" : "#E53E3E" }}>{selectedBet?.teamName}</b></Label>
              <Label>Apuesta: ${selectedBet?.bet}</Label>
            </div>
            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', alignItems: 'center' }}>
              <Label>Elige un equipo</Label>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                <BtnGreen
                  onClick={() => setSelectedTeam("1")}
                  data-selected={selectedTeam === "1"}
                >
                  {team1}
                </BtnGreen>
                <BtnRed
                  onClick={() => setSelectedTeam("2")}
                  data-selected={selectedTeam === "2"}
                >
                  {team2}
                </BtnRed>
              </div>
              <TextInput
                type="number"
                min="50"
                value={editedBet}
                onChange={(e) => setEditedBet(Number(e.target.value))}
                placeholder="Otra cantidad..."
                style={{ marginBottom: '5px' }}
              />
              <Button
                onClick={handleEditBet}
                style={{ width: '180px', padding: '4px', marginBottom: '5px' }}
                disabled={!editedBet || !selectedTeam || editedBet < selectedBet?.bet}
              >Guardar</Button>
              {showEditBetError && (
                <Alert
                  message={message}
                  type="error"
                  showIcon
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Hunt Bet */}
      <Modal
        closable={true}
        open={showModalHunt}
        onCancel={handleCloseHunt}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ModalTitle>Estas cazando una apuesta</ModalTitle>
          <p style={{ color: '#fff' }}>Equipo: <b style={{ color: selectedBet?.idTeam === "1" ? "#38A169" : "#E53E3E" }}>{selectedBet?.teamName}</b></p>
          <p style={{ color: '#fff' }}>Apuesta: ${selectedBet?.bet}</p>
          <Button onClick={huntBet} style={{ width: '180px', padding: '4px', marginBottom: '5px' }}>ACEPTAR</Button>
        </div>
      </Modal>

      {/* Modal Next Event*/}
      <Modal
        closable={true}
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ModalTitle>El evento ha terminado!</ModalTitle>
          <p style={{ color: '#fff' }}>No te preocupes, te llevaremos al siguiente evento automaticamente en: {countDown}</p>
          <Button onClick={handleCancelRedirect} style={{ width: '180px', padding: '4px', marginBottom: '5px' }}>QUEDARME AQUI</Button>
        </div>
      </Modal>

      {loading && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1100, // Asegúrate de que este valor sea mayor que el z-index del Modal
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Opcional: para oscurecer el fondo
            width: '100%',
            height: '600vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin
            style={{
              position: 'absolute', left: '50%', top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            size="large"
            spinning={loading}
          />
        </div>
      )}
    </div>
  );
};

export default Event;
