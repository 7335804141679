import { collection, query, where, getDocs, setDoc, doc, } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import "./NextFight.css";
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EventsDataService from "../Services/events.services";
import Alert from '@mui/material/Alert';
import { dbEvents, firestore, storage } from "../firebase";
import { Calendar, Badge, Upload, Col, Row, Select, Modal } from 'antd';
import { format } from 'date-fns';
import { useUserAuth } from "../context/UserAuthContext";
import { UploadOutlined } from "@ant-design/icons";
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Loading from "./Loading";

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "16.8px",
    textAlign: "center"
  }
})

const CssTextField = styled(TextField)({
  width: "100%",
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
    background: '#1a1a1a'
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
      background: "#1a1a1a",
      borderColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
        background: "#1a1a1a"
      },
    }
  },
});

const NextFight = () => {
  const [date, setDate] = useState(new Date());
  const [fight, setFight] = useState("");
  const [event, setEvent] = useState("");
  const [description, setDescription] = useState("");
  const [eventId, setEventId] = useState("");
  const [currentUrlEvent, setCurrentUrlEvent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const [eventImage, setEventImage] = useState({});
  const [currentImage, setCurrentImage] = useState({});
  const { getDaysInMonth } = require("date-fns");
  const { userInfo, loadSpin, loadingComponent } = useUserAuth();
  const classes = useStyles();

  const materialTheme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#6750A4",
        },
      },
    },
  });



  const getFightData = async (day1, day2) => {
    // Activa el spinner
    loadSpin(true);

    try {
      const ref = collection(firestore, "NextEvents");
      const q = query(ref, where("date", ">=", day1), where("date", "<", day2));

      const items = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const date = new Date(data.date.seconds * 1000 + data.date.nanoseconds / 1000000);
        items.push({
          mes: date.getMonth(),
          id: doc.id,
          content: data.event,
          description: data.description,
          fecha: date.getUTCDate(),
          date: format(date, 'yyyy-MM-dd'),
          type: 'success',
          urlEvent: data.urlEvent,
        });
      });

      // Actualiza el estado con los datos obtenidos
      setFight(items);
    } catch (error) {
      console.error("Error al obtener datos de peleas:", error);
    } finally {
      // Desactiva el spinner
      loadSpin(false);
    }
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (event === "" || description === "" || date === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    const id = uuidv4();
    const storageRefEvent = ref(storage, `event/${id}/event.png`);
    const metadata = {
      contentType: "image/jpeg",
      customMetadata: {
        eventId: id
      }
    };
    try {
      await uploadBytes(storageRefEvent, eventImage, metadata);
      const urlEvent = await getDownloadURL(storageRefEvent);
      const newEvent = {
        event,
        description,
        date,
        urlEvent
      };
      await setDoc(doc(dbEvents, "NextEvents", id), newEvent);
      setMessage({ error: false, msg: "¡Nuevo evento añadido!" });
      window.location.reload(false);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (event === "" || description === "" || date === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    try {
      if (Object.keys(eventImage).length !== 0) {
        const id = uuidv4();
        const storageRefEvent = ref(storage, `event/${eventId}/event.png`);
        const metadata = {
          contentType: "image/jpeg",
          customMetadata: {
            eventId: id
          }
        };
        await uploadBytes(storageRefEvent, eventImage, metadata);
        setCurrentUrlEvent(await getDownloadURL(storageRefEvent))
      }
      const newEvent = {
        event,
        description,
        date,
        urlEvent: currentUrlEvent
      };
      await EventsDataService.updateEvent(eventId, newEvent);
      setMessage({ error: false, msg: "¡Evento actualizado!" });
      window.location.reload(false);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const confirmationDelete = async (e) => {
    setOpenModalEdit(false)
    setOpenModalDelete(true)
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      await EventsDataService.deleteEvent(eventId);
      setMessage({ error: false, msg: "¡Evento eliminado!" });
      window.location.reload(false);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const handleBeforeUpload = (file, type) => {
    const isImage = file.type.indexOf("image/") === 0;
    const isJpg = file.type.includes("jpg");
    const isJpeg = file.type.includes("jpeg");
    const isPng = file.type.includes("png");
    const isWebp = file.type.includes("webp");

    if (!isJpg && !isJpeg && !isPng && !isWebp) {
      message.error("Sólo se permite formato jpg, jpeg, png y webp!", 3);
      return Upload.LIST_IGNORE;
    }

    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 2MB!", 3);
      return Upload.LIST_IGNORE;
    }
    if (type === "event") setEventImage(file);
    return false;
  };

  const handleRemove = (file, type) => {
    if (type === "event") setEventImage({});
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const cellRender = (current, info) => {
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const onCancel = () => {
    setOpenModal(false);
    setOpenModalDelete(false)
    setOpenModalUser(false)
    setOpenModalEdit(false)
  };

  const handleDate = (dates) => {

    setDate(new Date(dates + "T15:00:00.000"))

  };

  useEffect(() => {
    const daysInMonth = getDaysInMonth(new Date())
    const day1 = new Date()
    day1.setHours(0, 0, 0, 0);
    const currentDays = day1.getUTCDate() - 1
    const startDate = new Date(dayjs(day1).subtract(currentDays, "day"))
    const currentDays2 = daysInMonth - 1
    const endDate = new Date(dayjs(startDate).add(currentDays2, "day"));
    getFightData(startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loadingComponent &&
        <Loading />
      }
      {!loadingComponent && <div className='container-calendar'>
        <div className="site-calendar-demo-card">
          <div className="container-event">
            {(userInfo.type === "admin" || userInfo.type === "finance") &&
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="button-add-event">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{ mt: 5, mb: 4 }}
                      style={{
                        color: "#FFFFFF",
                        borderRadius: 15,
                        marginTop: "1em",
                        backgroundColor: "rgb(120, 17, 19)",
                        padding: "10px 36px",
                        fontSize: "14px",

                      }}
                      onClick={(e) => setOpenModal(true)}
                    >
                      <div style={{ alignItems: "center", display: "flex" }}><AddIcon />
                        <Typography sx={{ fontSize: 16 }} align="center" style={{ fontWeight: "bold", paddingTop: "1px" }} >
                          Agregar Evento
                        </Typography></div>
                    </Button>
                  </div>
                </div>
                {/* modal crear evento */}
                <Modal
                  open={openModal}
                  onCancel={onCancel}
                  footer={null}
                  centered
                >
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                    <Typography align="center" className={classes.typography} style={{ fontSize: "22px", color: "#fff", fontWeight: "normal", marginBottom: "10px" }}>
                      Crear evento
                    </Typography>
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      id="formEvent"
                      label="Nombre de la pelea"
                      name="event"
                      onChange={(e) => setEvent(e.target.value)}
                    />
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      id="formDescription"
                      label="Descripción de la pelea"
                      name="description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <Typography
                      name="eventImage"
                      valuePropName="eventImage"
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: "Debe subir una imágen para el evento",
                        },
                      ]}
                    >
                      <Upload
                        beforeUpload={(file) => handleBeforeUpload(file, "event")}
                        onRemove={(file) => handleRemove(file, "event")}
                        maxCount={1}
                        listType="picture"
                      >
                        <Button style={{ color: "rgb(56, 161, 105)" }} className="inputRegister" icon={<UploadOutlined />}>
                          Seleccione una imagen
                        </Button>
                      </Upload>
                    </Typography>
                    <Grid direction="row" justifyContent="flex-start" container rowSpacing={3} columnSpacing={{ sx: 0, sm: 0, md: 2, lg: 2 }}>
                      <ThemeProvider theme={materialTheme}>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "1em" }}>
                          <CssTextField
                            style={{ width: "50%", marginRight: "1em" }}
                            margin="normal"
                            required
                            id="date"
                            type="date"
                            defaultValue={new Date().toISOString().split('T')[0]}
                            onChange={(e) => handleDate(e.target.value)}
                            inputProps={{
                              style: {
                                height: "40px",
                                paddingLeft: "15px"
                              },
                            }}
                          />
                        </div>
                      </ThemeProvider>
                    </Grid>
                    {message?.msg && (
                      <Alert
                        severity={message?.error ? "error" : "success"}
                        dismissible
                        onClose={() => setMessage("")}
                      >
                        {message?.msg}
                      </Alert>
                    )}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 4 }}
                        style={{
                          color: "#FFFFFF",
                          borderRadius: 15,
                          marginTop: "1em",
                          backgroundColor: "rgb(120, 17, 19)",
                          padding: "10px 36px",
                          fontSize: "14px"
                        }}
                      >
                        Crear
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </div>}
            {/* modal vista usuario */}
            <Modal
              open={openModalUser}
              onCancel={onCancel}
              footer={null}
              centered
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="box-description">
                  <div className={classes.typography} style={{ fontSize: "24px", color: "#fff", fontWeight: "normal", marginBottom: "10px" }}>
                    {event}
                  </div>
                  <div className={classes.typography} style={{ fontSize: "14px", color: "#fff", fontWeight: "normal", Top: "20px" }}>
                    {description}
                  </div>
                </div>
              </div>
              {currentUrlEvent &&
                <div className="container-image">
                  <div>
                    <img className="container-image-event" src={currentUrlEvent} alt="eventImage" />
                  </div>
                </div>}
            </Modal>
            {/* editar evento */}
            <Modal
              open={openModalEdit}
              onCancel={onCancel}
              footer={null}
              centered
            >
              <div>
                <div className="modal-container">
                  <Typography align="center" className={classes.typography} style={{ fontSize: "22px", color: "#fff", fontWeight: "normal", marginBottom: "10px" }} >
                    Editar evento
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      id="formEvent"
                      label="Nombre de la pelea"
                      name="event"
                      value={event}
                      onChange={(e) => setEvent(e.target.value)}
                    />
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      id="formDescription"
                      label="Descripción de la pelea"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {message?.msg && (
                      <Alert
                        severity={message?.error ? "error" : "success"}
                        dismissible
                        onClose={() => setMessage("")}
                      >
                        {message?.msg}
                      </Alert>
                    )}
                    <Typography
                      name="eventImage"
                      valuePropName="eventImage"
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: "Debe subir una imágen para el evento",
                        },
                      ]}
                    >
                      <Upload
                        beforeUpload={(file) => handleBeforeUpload(file, "event")}
                        onRemove={(file) => handleRemove(file, "event")}
                        maxCount={1}
                        listType="picture"
                        defaultFileList={currentImage}
                      >
                        <Button style={{ color: "rgb(56, 161, 105)" }} className="inputRegister" icon={<UploadOutlined />}>
                          Seleccione una imagen
                        </Button>
                      </Upload>
                    </Typography>
                  </Box>
                </div>
                <div style={{ display: "flex", justifyContent: "center", padding: "0em 2em 0em 2em;" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 5, mb: 4 }}
                    style={{
                      color: "#FFFFFF",
                      borderRadius: 13,
                      marginTop: "1em",
                      marginRight: "1em",
                      backgroundColor: "rgb(28 91 7)",
                      padding: "10px 36px",
                      fontSize: "14px"
                    }}
                    onClick={(e) => handleEdit(e)}
                  >
                    Guardar
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 5, mb: 4 }}
                    style={{
                      color: "#FFFFFF",
                      borderRadius: 13,
                      marginTop: "1em",
                      backgroundColor: "rgb(120, 17, 19)",
                      padding: "10px 36px",
                      fontSize: "14px"
                    }}
                    onClick={(e) => confirmationDelete(e)}
                  >
                    Eliminar
                  </Button>
                </div>
              </div>
            </Modal>
            {/* confimation modal */}
            <Modal
              open={openModalDelete}
              onCancel={onCancel}
              footer={null}
              centered
            >
              <div>
                <div className="modal-container">
                  <Typography align="center" className={classes.typography} style={{ fontWeight: "bold", paddingTop: "1px", fontSize: "22px", color: "#fff" }} >
                    ¿Estás seguro?
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "center", padding: "2em" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 5, mb: 4 }}
                    style={{
                      color: "#FFFFFF",
                      borderRadius: 15,
                      marginTop: "1em",
                      marginRight: "1em",
                      backgroundColor: "rgb(28 91 7)",
                      padding: "10px 36px",
                      fontSize: "14px"
                    }}
                    onClick={(e) => handleDelete(e)}
                  >
                    Sí
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 5, mb: 4 }}
                    style={{
                      color: "#FFFFFF",
                      borderRadius: 15,
                      marginTop: "1em",
                      backgroundColor: "rgb(120, 17, 19)",
                      padding: "10px 36px",
                      fontSize: "14px"
                    }}
                    onClick={(e) => setOpenModalDelete(false)}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Modal>
            <div className="container-text-next-fight">
              <Typography sx={{ fontSize: 22 }} color={'#fff'} align="left" style={{ fontWeight: "bold", paddingBottom: "10px" }} >
                Próximos eventos
              </Typography>
            </div>
            <div className="card-calendar">
              <div className="site-calendar-demo-card">
                <Calendar
                  fullscreen={true}
                  dateCellRender={(date) => {
                    for (let i = 0; i < fight.length; i++) {
                      if (format(new Date(date), 'yyyy-MM-dd') === fight[i].date) {
                        return <div className="container-text-calendar">
                          <Badge className="content-badge" status="success" />
                          <h5 className="content-text">{fight[i].content}</h5>
                        </div>
                      }
                    }
                  }}
                  onChange={(date) => {
                    const daysInMonth = getDaysInMonth(date._d)
                    const day1 = date._d
                    day1.setHours(0, 0, 0, 0);
                    const currentDays = day1.getUTCDate() - 1
                    const startDate = new Date(dayjs(day1).subtract(currentDays, "day"));
                    const currentDays2 = daysInMonth - 1
                    const endDate = new Date(dayjs(startDate).add(currentDays2, "day"));
                    getFightData(startDate, endDate)
                  }}
                  onSelect={(date) => {

                    if (userInfo.type !== "admin" && userInfo.type !== "finance") {
                      const allItems = []
                      for (let i = 0; i < fight.length; i++) {
                        if (format(new Date(date), 'yyyy-MM-dd') === fight[i].date) {
                          allItems.push(fight[i])
                        }
                      }
                      if (allItems.length > 0) {
                        setOpenModalUser(true)
                        setCurrentUrlEvent(allItems[0].urlEvent)
                        setEvent(allItems[0].content)
                        setDescription(allItems[0].description)
                      }
                    } else {
                      const allItems = []
                      for (let i = 0; i < fight.length; i++) {
                        if (format(new Date(date), 'yyyy-MM-dd') === fight[i].date) {
                          allItems.push(fight[i])
                        }
                      }
                      if (allItems.length > 0) {
                        setEventId(allItems[0].id)
                        setDate(date._d)
                        setEvent(allItems[0].content)
                        setDescription(allItems[0].description)
                        setCurrentImage([{ url: allItems[0].urlEvent }])
                        setCurrentUrlEvent(allItems[0].urlEvent)
                        setOpenModalEdit(true)
                      }
                    }
                  }}
                  disabledDate={(date) => {
                    if (userInfo.type !== "admin" && userInfo.type !== "finance") {
                      const allItems = []
                      for (let i = 0; i < fight.length; i++) {
                        if (format(new Date(date), 'yyyy-MM-dd') === fight[i].date && date._d.getMonth() >= new Date().getMonth()) {
                          allItems.push(fight[i])
                          return false;
                        }
                      }
                      return true
                    }
                  }}
                  headerRender={({ value, type, onChange, onTypeChange }) => {
                    const start = 0;
                    const end = 12;
                    const monthOptions = [];

                    const current = value.clone();
                    const localeData = value.localeData();
                    const months = [];
                    for (let i = 0; i < 12; i++) {
                      current.month(i);
                      months.push(localeData.monthsShort(current));
                    }

                    for (let i = start; i < end; i++) {
                      monthOptions.push(
                        <Select.Option key={i} value={i} className="month-item">
                          {months[i]}
                        </Select.Option>,
                      );
                    }

                    const year = value.year();
                    const month = value.month();
                    const options = [];
                    for (let i = year - 10; i < year + 10; i += 1) {
                      options.push(
                        <Select.Option key={i} value={i} className="year-item">
                          {i}
                        </Select.Option>,
                      );
                    }
                    return (
                      <div style={{ padding: 8 }}>
                        <Row gutter={8}>
                          <Col>
                            <Select
                              size="small"
                              dropdownMatchSelectWidth={false}
                              value={month}
                              onChange={newMonth => {
                                const now = value.clone().month(newMonth);
                                onChange(now);
                              }}
                            >
                              {monthOptions}
                            </Select>
                          </Col>
                          <Col>
                            <Select
                              size="small"
                              dropdownMatchSelectWidth={false}
                              className="my-year-select"
                              value={year}
                              onChange={newYear => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                              }}
                            >
                              {options}
                            </Select>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                  cellRender={cellRender}
                />
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div >
  );
};
export default NextFight;
