import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import { useUserAuth } from "../context/UserAuthContext";

const InfoAlert = () => {
  const { alert } = useUserAuth();
  const [localAlertVisible, setLocalAlertVisible] = useState(true);

  useEffect(() => {
    if (alert?.public) {
      setLocalAlertVisible(true);
    }
  }, [alert]);

  const handleClose = () => {
    setLocalAlertVisible(false);
  };

  return (
    <>
      {alert?.public && localAlertVisible && (
        <Alert
          message={
            <span style={{ color: "#FFF", fontWeight: "bold", fontSize: "24px" }}>{alert.title}</span>
          }
          description={
            <span style={{ fontSize: "20px" }}>{alert.description}</span>
          }
          type="info"
          closable
          onClose={handleClose}
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
            borderRadius: "15px",
            border: "1px solid rgb(63, 63, 63)",
            boxShadow: "0 4px 16px rgba(0, 0, 0, 0.15)",
            backgroundColor: "rgb(56, 161, 105)",
            color: "#fff",
            marginBottom: "30px",
            padding: "20px",
            fontSize: "16px",
            width: "90%",
            maxWidth: "500px",
          }}
          closeIcon={
            <span
              style={{
                color: "#fff",
                fontSize: "18px",
                lineHeight: "1",
              }}
            >
              ✖
            </span>
          }
        />
      )}
    </>
  );
};

export default InfoAlert;
